import {
    ACTIVATION_CODE,
    ADD_PROFILE,
    APPROVED_ASSET,
    ASSET_PAYMENT,
    BRAINTREE_CUSTOMER,
    GENERATE_ACTIVATION_CODE,
    GENERATE_USER_RELATION,
    GET_ADDRESS,
    GET_ALL_PARTNERS,
    GET_ASSET_INFO,
    GET_COUNTRIES,
    GET_EVENT_INFO,
    GET_EVENTS,
    GET_REGIONS,
    GET_USER,
     GET_WEBSITE_DEFAULT_DATA,
    ITEMS_IS_LOADING,
    GET_CLIPS,
    CREATE_CLIPS,
    GIVE_VOTE,
    GET_LOCATION,
} from './ActionTypes'

/////////////////ACTIONS//////////////
export const activationCodeProps = (activationCodeState) => ({ type: ACTIVATION_CODE, activationCodeState });
export const approvedProps = (approvedState) => ({ type: APPROVED_ASSET, approvedState });
export const assetInfo = (asset) => ({ type: GET_ASSET_INFO, asset });
export const assetPayment = (asset) => ({ type: ASSET_PAYMENT, asset });
export const braintreeCustomer = (braintreeCustomerState) => ({ type: BRAINTREE_CUSTOMER, braintreeCustomerState });
export const dispatch_addprofile = (addProfile) => ({ type: ADD_PROFILE, addProfile });
export const dispatch_address = (address) => ({ type: GET_ADDRESS, address });
export const dispatch_countries = (countries) => ({ type: GET_COUNTRIES, countries });
export const dispatch_events = (events) => ({ type: GET_EVENTS, events });
export const eventInfo = (event) => ({ type: GET_EVENT_INFO, event });
export const generateActivationCodeProps = (generateActivationCodeState) => ({ type: GENERATE_ACTIVATION_CODE, generateActivationCodeState });
export const generateUserRelationProps = (userRelationData) => ({ type: GENERATE_USER_RELATION, userRelationData });
export const getPartners = (partners) => ({ type: GET_ALL_PARTNERS, partners });
export const itemsIsLoading = (loading) => ({ type: ITEMS_IS_LOADING, loading });
export const regions = (regionsState) => ({ type: GET_REGIONS, regionsState });
export const userInfo = (user) => ({ type: GET_USER, user });
export const websiteDefaults = (websiteDefaultData) => ({ type: GET_WEBSITE_DEFAULT_DATA, websiteDefaultData });
export const allClips = (clips) => ({ type: GET_CLIPS, clips });
export const createdClip = (clips) => ({ type: CREATE_CLIPS, clips });
export const votedProps = (votedState) => ({ type: GIVE_VOTE, votedState });
export const dispatch_location = (location_state) => ({ type: GET_LOCATION, location_state });