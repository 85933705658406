import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import axios from "axios";
import * as config from '../../config';
import moment from 'moment';

import { getEvents } from '../../redux/reducers/reducer';

// Components
import AlertPopup from '../payment/AlertPopup';
import BuyOptionsPopupMaster from './BuyOptionsPopupMaster';
import Error from './Error';
import Loader from '../Loader';

// Styles
import './watchparty-theme.css';

class Welcome extends Component {

  state = {
    buyStatus: true,
    errorMsg: '',
    eventInfo: {},
    eventsData: [],
    playbackUrl: config.DEFAULT_VIDEO_STREAM,
    role: 'host',
    showBuyPopup: false,
    showError: false,
    showMessage: false,
    title: '',
    username: localStorage.getItem('firstname'),
  }

  constructor() {
    super();
    this.baseHref = config.BASE_HREF;
    this.inputRef = React.createRef();
    this.onChangeSearch = this.onChangeSearch.bind(this);
    this.onShareClick = this.onShareClick.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.okClick = this.okClick.bind(this);
  }

  componentDidMount() {
    const qs = new URLSearchParams(this.props.location.search);
    const action = qs.get('action');
    window.token = localStorage.getItem('token');
    if (!window.token) {
      this.props.history.push("/");
    }
    if (action === 'join') {
      const title = qs.get('room');
      this.props.history.push(`${this.baseHref}/join?room=${title}`);
    }
    else if (localStorage.getItem('redirecto')) {
      this.props.history.push(localStorage.getItem('redirecto'));
      localStorage.removeItem('redirecto')
    }

    //this.inputRef.current.focus();
    let eventType = ['watchParty']
    //this.props.getEvents(null, eventType);
    let siteSettings = window.site;
    let envoiScreeningApiBaseUrl = siteSettings && siteSettings.screeningApiBaseUrl;
    let body;
        if (eventType){
            body = {"eventType": eventType,}
            
        }
    axios.post(envoiScreeningApiBaseUrl + '/getEvents?appname=' + window.app.appName + '&env=' + window.app.appEnvName + '&token=' + window.token, body, { type: 'application/json' })
      .then((response) => {
        if(response && response.data && response.data.result){
          this.setState({ eventsData: response.data.result.data })
        }
        
      })
      .catch((err) => {
        console.error.bind("reducer.js approvedAsset", { err });
      })
  }

  componentDidUpdate(prevProps) {
    if (prevProps.events !== this.props.events) {
      let eventsData = (this.props.events || {}).data || [];
      this.setState({ eventsData })
    }
  }
  closeModal(e) {
    e.stopPropagation();
    e.preventDefault();
    this.setState({ showBuyPopup: false, shareClickPopup: false, isSubscriptionOpen: false });
  }
  onShareClick() {
    let self = this;
    const { eventInfo } = this.state;
    if (eventInfo.availability && eventInfo.availability.type === "Subscription") {
      self.setState({ isSubscriptionOpen: true }, () => {
        self.setState({
          showBuyPopup: true,
        });
      });
    }
    else {
      self.setState({ shareClickPopup: true }, () => {
        self.setState({
          showBuyPopup: true,
        });
      });
    }
  }

  handleNameChange = e => {
    this.setState({ username: e.target.value })
  }

  onChangeSearch(event) {
    const q = event.target.value.toLowerCase();
    this.setState({ q }, () => this.searchFilter());
  }
  searchFilter() {
    let eventsData = (this.props.events || {}).data || [];
    let q = this.state.q;
    eventsData = eventsData.filter(function (event) {
      return event.title.toLowerCase().indexOf(q) !== -1; // returns true or false
    });
    this.setState({ eventsData });
  }
  handleRoomChange = e => {
    const { eventsData, playbackUrl } = this.state;
    console.log('eventsData',eventsData,playbackUrl)
    let value = e.target.value;
    let rooms = eventsData.filter(obj => obj.event_id === value);
    let room = rooms[0];
    if (room) {
      let title = room.title;
      let url = room.url ? room.url : playbackUrl
      this.setState({ title, playbackUrl: url, event_id: value, eventInfo: room })
    }
    // console.log("room", room);
    // this.setState({ title: e.target.value })
  }

  handlePlaybackURLChange = e => {
    this.setState({ playbackURL: e.target.value })
  }

  handleCreateRoom = (e, event_id) => {
    e.preventDefault();
    e.stopPropagation();
    const { eventsData, playbackUrl } = this.state;
    let value = event_id;
    let rooms = eventsData.filter(obj => obj.event_id === value);
    let room = rooms[0];
    if (room) {
      let title = room.title;
      let url = room.url ? room.url : playbackUrl;
      this.setState({ title, playbackUrl: url, event_id: value, eventInfo: room }, () => this.createRoom());
    }
  }

  setErrorMsg = errorMsg => {
    this.setState({ errorMsg, showError: true });
  }

  closeError = () => {
    this.setState({ showError: false });
  }

  async createRoom() {
    let self = this;
    const { title, username, playbackUrl, eventInfo } = this.state;
    let buyStatus = true;
    if (eventInfo.availability && eventInfo.availability.type !== 'Free') {
      buyStatus = !!(
        (eventInfo.hasOwnProperty('buy') && eventInfo.buy) ||
        (eventInfo.hasOwnProperty('rent') && eventInfo.rent) ||
        (eventInfo.hasOwnProperty('gift') && eventInfo.gift)
      )
    }
    self.setState({ buyStatus });

    const data = {
      username,
      title,
      playbackURL: playbackUrl,
      role: this.state.role,
      item: eventInfo
    }
    sessionStorage.setItem(`chime[${title.replace(/\s/g, '')}]`, JSON.stringify(data));
    let isAvailable = true;
    if (eventInfo.availability && eventInfo.availability.availableFrom && eventInfo.availability.availableTo) {
      let availabilityStartAsDate = moment(eventInfo.availability.availableFrom)
      let availabilityEndAsDate = moment(eventInfo.availability.availableTo)
      let now = moment(new Date());
      console.debug('Welcome.js createRoom', { now, availabilityStartAsDate, availabilityEndAsDate });

      // let availabilityStart = availStartAsDate.format("YYYY-MM-DD HH:mm:ss");
      // let availabilityEnd = availEndAsDate.format("YYYY-MM-DD HH:mm:ss");
      // let nowFormatted = now.format("YYYY-MM-DD HH:mm:ss");
      // if (moment(nowFormatted).isAfter(availabilityStart) && moment(now).isBefore(availabilityEnd)) {
      if (now.isAfter(availabilityStartAsDate) && now.isBefore(availabilityEndAsDate)) {
        console.log('Welcome.js createRoom - its available');
        isAvailable = true;
      } else {
        console.log('Welcome.js createRoom - not available', { buyStatus });
        if (!buyStatus) {
          isAvailable = false;
          this.setState({ showMessage: true });
        }
      }
    }
    if (buyStatus || eventInfo.buy || eventInfo.rent || eventInfo.gift || (eventInfo.availability && eventInfo.availability.free && eventInfo.availability.free.allow)) {
      this.props.history.push(`${this.baseHref}/meeting?room=${title}`);
    } else {
      if (eventInfo && !eventInfo.buy && !eventInfo.rent && !eventInfo.gift && eventInfo.availability && eventInfo.availability.buyplans) {
        if (eventInfo.availability.buyplans.rent) {
          delete eventInfo.availability.buyplans.rent;
          self.setState({ eventInfo })
        }
        if (isAvailable) {
          self.setState({
            showBuyPopup: true,
            shareClickPopup: true
          })
        }

      } else {
        isAvailable = false;
        this.setState({ showMessage: true });
      }
    }


  }
  logOut(e) {
    if (window.site && window.site.config.authentication.key === "auth0") {
      if (window.signinLock) {
        window.signinLock.logout()
        console.log('logged out');
      }
    }
    e.stopPropagation();
    e.preventDefault();
    let appStorage = window.app.storage;
    let appName = appStorage.getItem('appName');
    let appEnvName = appStorage.getItem('appEnvName');
    let windowLocation = appStorage.getItem('windowLocation');
    appStorage.clear();
    if (appName) appStorage.setItem('appName', appName);
    if (appEnvName) appStorage.setItem('appEnvName', appEnvName);
    if (windowLocation) appStorage.setItem('windowLocation', windowLocation);
    window.location = '/';
  };
  okClick() {
    let self = this;
    self.setState({ showMessage: false });
  }
  render() {
    let self = this;
    const { eventsData, showBuyPopup, buyStatus } = self.state;
    let siteSettings = window.site;
    let siteConfig = siteSettings.config;
    let imagesPath = siteConfig.imagesAssetResourcesPrefix;

    let wiw_profileImage = localStorage.getItem("userImage");

    if (wiw_profileImage === null || wiw_profileImage === "" || wiw_profileImage === 'undefined') {
      wiw_profileImage = imagesPath + siteSettings.themePrefix + "profiles/default/avatar1.jpg"
    }
    return (
      <React.Fragment>
        {/* <h3><NavLink href="#" style={{marginLeft:"20px"}} onClick={(e) => this.logout(e)}>LOGOUT</NavLink></h3> */}
        <div className="welcomechime form-grid">

          <div className="intro_bg digi_bg"
          // style={{ background: `url(` + imagePrefix + `submission/images/landscape_background.jpg)` }}
          >
            <div className="intro__inner formatted-text">
              <img alt="landinglogo" src={siteConfig.imagePrefix + "screening/images/landingpagelogo.png" + siteConfig.imageSuffix} className="room_logo" />
              <h1>{siteConfig.siteTitle} Screening Room</h1>
              <h3>Select the Room to join the Watch Party</h3>
            </div>
          </div>


          <div className="welcome_container pad4_2">
            <div className="head_bar flex_display space_between mb-5">
              <div className="search_block">
                <i className="material-icons">search</i>
                <input type="text" className="search_bar" onChange={this.onChangeSearch} placeholder="Search Rooms..." />
              </div>
              {/* <a className="sm_btn logout_btn" href="#" onClick={(e) => this.logout(e)}>Log Out</a> */}
              <div className="btn-group">
                <button onClick={ev => { ev.preventDefault() }} className=" dropdown-toggle pr-2 pl-1" data-toggle="dropdown">
                  <img alt="user" src={wiw_profileImage} className="user_pic" />
                </button>
                <div className="dropdown-menu u-ralign-0">
                  <li>
                    <a className="dropdown-item mng_prf" href="#!" onClick={(e) => self.logOut(e)}>
                      Logout</a></li>
                  {/* <li>
                    <a className="dropdown-item mng_prf" href="#" onClick={this.logout.bind(this)}>
                      Manage Profiles</a></li>
                  <li>
                    <a className="dropdown-item mng_prf" href="#" onClick={this.logout.bind(this)}>
                      Account Settings</a></li> */}
                </div>
              </div>
            </div>
            <div className="head_body">
              <div className="row events_list">
                {eventsData && eventsData.length > 0 && eventsData.map((event, i) => {
                  return (
                    <div key={i} className="col5">
                      <div className="thumbnail" onClick={(e) => this.handleCreateRoom(e, event.event_id)}>
                        <div className="thumb_overlay" >
                          <h3>{event.title} </h3>
                          <div className="footer_btns">
                            <button onClick={ev => { ev.preventDefault() }}>{event.availability && event.availability.buyplans && event.availability.buyplans.buy && event.availability.buyplans.buy.contentPrice ? '$ ' + event.availability.buyplans.buy.contentPrice[Object.keys(event.availability.buyplans.buy.contentPrice)[0]] : event.status}</button>
                            <button onClick={ev => { ev.preventDefault() }}>JOIN</button>
                          </div>
                        </div>
                        <img alt="event" src={event.portrait ? event.portrait : event.images && event.images.length > 0 ? event.images[0] : siteConfig.defaultEvent} />
                      </div>
                    </div>
                  )
                }
                )}
              </div>
            </div>
          </div></div>
        {showBuyPopup && <BuyOptionsPopupMaster history={this.props.history} buyStatus={buyStatus} assetId={self.state.eventInfo.event_id} shareasset={self.state.eventInfo} isOpen={self.state.showBuyPopup} open={self.onShareClick} close={self.closeModal} />}
        {this.props.loading && <Loader />}
        {this.state.showMessage &&
          <AlertPopup message="Sorry, this video is not available for viewing yet." closePopup={this.okClick} title='NOT AVAILABLE' imagePath="images/states_of_applications/warning_gray.png" showClose={true} />
        }
        {this.state.showError && (
          <Error
            closeError={this.closeError}
            errorMsg={this.state.errorMsg}
          />
        )}
      </React.Fragment>
    )
  }
}

// export default withRouter(Welcome);
const mapState = ({ events, loading }) => ({ events, loading });
const mapDispatch = { getEvents };
export default withRouter(connect(mapState, mapDispatch)(Welcome));
