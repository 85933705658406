import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import * as config from '../../config';
import Error from './Error';
import { connect } from 'react-redux';
import { getEvents, getEventInfo} from '../../redux/reducers/reducer';
let defaultImage = "https://envoi-common-resources.imgix.net/paff/screening/images/watchparty/asset.png";
let token = window.app.storage.getItem('token');

class Join extends Component {

  state = {
    role: 'attendee',
    username: '',
    title: '',
    errorMsg: '',
    showError: false,
    eventDetails: {},
  }

  constructor(props) {
    super(props);

    this.inputRef = React.createRef();
    this.baseHref = config.BASE_HREF;
  }

  componentDidMount() {
    token = window.app.storage.getItem('token');
    const qs = new URLSearchParams(this.props.location.search);
    const room = qs.get('room');
    const event_id = qs.get('id');
    if(!token){
      window.app.storage.setItem('redirecto',this.props.location.pathname+this.props.location.search);
      this.props.history.push("/");   
    }
    this.setState({ title: room });
    //const event_id = 7041;
   // this.props.getEvents(event_id);
    if(event_id){
      this.props.getEventInfo(event_id);
     }
  }

  componentDidUpdate(prevProps, prevState) {
    let self = this;
    if (prevProps.eventInfo !== this.props.eventInfo) {
      self.setState({ eventDetails: this.props.eventInfo })
    }
  }


  joinRoom = async () => {
    const { username, title, eventDetails} = this.state;
    const data = {
      username,
      title,
      role: this.state.role,
      item: eventDetails
    }
    console.log('data',`chime[${title}]`);
    sessionStorage.setItem(`chime[${title}]`, JSON.stringify(data));
    this.props.history.push(`${this.baseHref}/meeting?room=${title}`);
  }

  handleNameChange = e => {
    this.setState({ username: e.target.value })
  }

  handleJoinRoom = (e) => {
    e.preventDefault();
    e.stopPropagation();

    this.joinRoom();
  }

  handleClick = (e) => {
    let node = e.target;
    let isModal = false;
    while (node) {
      if (node && node.classList && node.classList.contains('notice--error')) {
        isModal = true;
        break;
      }
      node = node.parentNode;
    }
    if (!isModal) {
      this.closeError();
    }
  }

  setErrorMsg = errorMsg => {
    this.setState({ errorMsg, showError: true });
  }

  closeError = () => {
    this.setState({ showError: false });
  }

  logout() {
    let appStorage = window.app.storage;
    let appName = appStorage.getItem('appName');
    let appEnvName = appStorage.getItem('appEnvName');
    let windowLocation = appStorage.getItem('windowLocation');
    appStorage.clear();
    console.log('appName',appName);
    if (appName) appStorage.setItem('appName', appName);
    if (appEnvName) appStorage.setItem('appEnvName', appEnvName);
    if (windowLocation) appStorage.setItem('windowLocation', windowLocation);
    window.location = '/';
  }

  render() {
    const { username, title, eventDetails } = this.state;
    const joinRoomDisabled = !username;
    let image = (eventDetails && eventDetails.images && eventDetails.images.length > 0) ? (eventDetails.images[0] ? eventDetails.images[0] : eventDetails.images[1] ? eventDetails.images[1] : eventDetails.images[2] ? eventDetails.images[2] : defaultImage) : defaultImage;
    return (
      <div className="welcomechime form-grid" onClick={this.handleClick}>
        <div className="intro_bg" >
          <div class="asset_blurbg" style={{backgroundImage: 'url(' + image + ')'}}/>
          {/* <div className="intro_bg digi_bg"> */}
          <div className="intro__inner formatted-text">
            <img src={window.site.config.imagePrefix + "screening/images/landingpagelogo.png" + window.site.config.imageSuffix} className="groupicon" alt="" />
            <h1>{title}</h1>
            <h3>Join the Watch Party</h3>
          </div>
          <img src={image} class="asset_thumbnail" alt=""/>
          {/* </div> */}
        </div>

        <div className="welcome__content pd-4">
        <button className="logoutbtnwatch" href="#" onClick={(e) => this.logout(e)}>Log Out</button>
          <div className="content__inner">
            <h2 className="mg-b-2">Welcome to the {title} - Watch Party</h2>
            <form action="">
              <fieldset className="mg-b-2">
                <input type="text" placeholder="Your name" value={username} ref={this.inputRef} onChange={this.handleNameChange} />
                <button className="mg-t-1 btn btn--primary" disabled={joinRoomDisabled} onClick={this.handleJoinRoom}>Join Watch Party</button>
              </fieldset>
            </form>
          </div>
        </div>

        {this.state.showError && (
          <Error
            closeError={this.closeError}
            errorMsg={this.state.errorMsg}
          />
        )}
      </div>
    )
  }
}

// export default withRouter(Join);
const mapState = ({ events, eventInfo}) => ({ events, eventInfo});
const mapDispatch = { getEvents, getEventInfo };
export default withRouter(connect(mapState, mapDispatch)(Join));
