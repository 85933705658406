import React, { Component } from 'react';
import { connect } from 'react-redux';
import Modal from 'react-modal';
import { browserHistory } from "react-router";
import { priceFormat } from '../../redux/reducers/reducer';
const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
    }
};
class BuyOptionsPopupMaster extends Component {
    constructor(props) {
        super(props);
        this.state = {
            shareClickPopup: this.props.isOpen,
            giftPeriod: '48'
        };
        this.giftClick = this.giftClick.bind(this);
        this.okClick = this.okClick.bind(this);


    }
    subscriptionClick(e, planId, price) {

        e.stopPropagation();
        e.preventDefault();
        localStorage.setItem("a", "subscrption");
        localStorage.setItem("assetPrice", price)
        
        this.props.history.push({
            pathname: "/subpayment/"+planId,
            eventInfo: this.props.shareasset,
          });
        // window.location = "/subpayment/"+planId
    }
    giftClick(e, shareasset) {
        let asset = shareasset && shareasset.assetid;
        let channelcode = shareasset && shareasset.channelcode;
        localStorage.setItem("assetId", asset);
        if (channelcode) {
            browserHistory.push("/giftedpayment/" + channelcode + '?t=g');
        } else if (asset) {

            browserHistory.push("/giftedpayment/" + asset);
        } else {
            this.props.close(e)
            this.setState({ showmessage: true });
        }
  }
    handleChange(event) {
        let value = event.target.value;
        this.setState({ giftPeriod: value })
    }
    onChange(e) {
        let self = this;
        const value = e.target.value;
        const name = e.target.name;
        self.setState({ "contentType": value, [name]: value })
    }
    giftChange(e, assetInfo) {
        e.stopPropagation();
        e.preventDefault();
        let self = this;
        let contentType = self.state.contentType;
        localStorage.setItem("contentType", contentType);
        localStorage.setItem("action", "gift");
        self.giftClick(e, assetInfo)
    }
    okClick(e) {
        e.stopPropagation();
        e.preventDefault();
        let self = this;
        self.setState({ showmessage: false });
    }
    handleSelect(e, assetInfo, type, selectedObj) {
        e.stopPropagation();
        e.preventDefault();
        let self = this;
        if (selectedObj && selectedObj.subscription) {
            self.subscriptionClick(e, selectedObj.planId, selectedObj.contentPrice.planPrice);
        } else {
            let contentType = self.state.contentType;
            let asset = assetInfo && assetInfo.assetid;
            let assetname = assetInfo && assetInfo.assetname;
            let channelcode = assetInfo && assetInfo.channelcode;
            let event_id = assetInfo && assetInfo.event_id;
            localStorage.setItem("contentType", contentType);
            localStorage.setItem("action", type);
            localStorage.setItem("assetId", asset);
            localStorage.setItem("assetName", assetname);
            
            let url = (type && type.toLowerCase() === 'gift' ? '/giftedpayment/' + channelcode + '?t=g' : '/Payment/' + channelcode + '?t=c');
            let browserUrl = (type && type.toLowerCase() === 'gift' ? '/giftedpayment/' + asset : '/buyAsset/' + asset);
            if (channelcode) {
                window.location = url;
            } else if (asset) {
                browserHistory.push(browserUrl);
            } else if(event_id){
                localStorage.setItem("eventInfo", JSON.stringify(assetInfo));
                this.props.history.push('/Payment/'+event_id);
            }
            else {
                this.props.close(e)
                this.setState({ showmessage: true });
            }
        }
    }

    render() {
        let self = this;
        const { showmessage } = this.state;
        const { shareasset, isOpen, close, isSubscriptionOpen, priceFormat } = this.props;
        let price = shareasset && shareasset.availability && shareasset.availability.buyplans && shareasset.availability.buy && shareasset.availability.buy.price;
        localStorage.setItem("price", price);
        if (shareasset && shareasset.channelname && shareasset.channelcode) {
            localStorage.setItem("title", shareasset.channelname);
            localStorage.setItem("channelcode", shareasset.channelcode);
        }
        let buyplans = shareasset && shareasset.availability && shareasset.availability.buyplans && Object.keys(shareasset.availability.buyplans).length > 0 ? shareasset.availability.buyplans : {};
        
        let giftingAsset = (buyplans && buyplans.gift && buyplans.gift.allow && buyplans.gift.contentPrice && Object.keys(buyplans.gift.contentPrice).length > 0) || false;

        

        if (buyplans['allow']) {
            delete buyplans['allow'];
        }
        return (
            <div>
                <Modal
                    isOpen={showmessage}
                    style={customStyles}
                    contentLabel="Example Modal"
                    className="custom_modal buy_options ">
                    <div className="modal-head pop_hed">
                        <h2> Subscription </h2>
                        <a href="#!" onClick={e => self.okClick(e)} className="closeBtn img-15">
                            <img alt="close" src={window.site.config.imagesAssetResourcesPrefix + window.site.themePrefix + "socialicons/close1.png"} />
                        </a>
                    </div>
                    <div className="modal-body nopad">
                        <div className="modalBody actionPopup">
                            <br />
                            <strong>Sorry!</strong> This asset is not available at this moment
                        </div>
                    </div>

                </Modal>
                <Modal
                    isOpen={isOpen}
                    style={customStyles}
                    contentLabel="Example Modal"
                    className="custom_modal buy_options autowidth">
                    <div className="modal-head pop_hed">
                        <h2> Buy Plans
                        <a href="#!" onClick={e => close(e)} className="closeBtn img-15">
                                <img alt="close" src={window.site.config.imagesAssetResourcesPrefix + window.site.themePrefix + "socialicons/close1.png"} />
                            </a>
                        </h2>
                    </div>
                    <div className="modal-body nopad">
                        <div className="modalBody actionPopup">
                            <div className="plan_ticketWrapper">
                                {Object.entries(buyplans).map(([item, buyPlanValue]) => {
                                        let obj = buyPlanValue || {};
                                        let contentPrice = obj.contentPrice || {};
                                        if (Object.keys(contentPrice).length <= 0) {
                                            return null;
                                        }
                                        return (
                                            <div key={item} className="plan_ticket">
                                                <div className="header">
                                                    <p>{item.toUpperCase()}</p>
                                                </div>
                                                <div className="body">

                                                    <p>{obj.rentalPeriod ? "Period - " + obj.rentalPeriod + ' Hrs' : ""} </p>

                                                    <p>{obj.viewsLimit ? "Views - " + obj.viewsLimit : ""}</p>
                                                    <p>{obj.downloadLimit ? "Downloads - " + obj.downloadLimit : ""}</p>
                                                    <p>{obj.deviceLimit ? "Devices - " + obj.deviceLimit : ""}</p>
                                                </div>
                                                <div className="footer">
                                                    <select name={item.toLowerCase() + 'Price'} className="buy-dd black_select" onChange={e => self.onChange(e)}>
                                                        <option value="">Select</option>
                                                        {
                                                            Object.keys(contentPrice).map((contentPriceKey, contentPriceIndex) => {
                                                                let contentPriceValue = obj.contentPrice[contentPriceKey]
                                                                if (contentPriceValue === 0) return null;
                                                                return (<option key={contentPriceIndex} value={contentPriceKey}>{contentPriceKey.toUpperCase()} {priceFormat(contentPriceValue, 'USD', 'en-US')}</option>)
                                                            })
                                                        }
                                                    </select>
                                                    <button onClick={e => self.handleSelect(e, shareasset, item, obj)} className={`selectplan_btn ${buyPlanValue.contentPrice && self.state[item.toLowerCase() + 'Price'] ? "" : "disabledTabs"}`}>SELECT PLAN</button>
                                                </div>
                                            </div>
                                        )
                                    })}
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer nopad nobord"/>
                </Modal>

                <Modal
                    isOpen={isSubscriptionOpen}
                    style={customStyles}
                    contentLabel="Example Modal"
                    className="custom_modal buy_options ">
                    <div className="modal-head pop_hed">
                        <h2> Subscription </h2>
                        <a href="#!" onClick={e => this.props.close(e)} className="closeBtn img-15">
                            <img alt="close" src={window.site.config.imagesAssetResourcesPrefix + window.site.themePrefix + "socialicons/close1.png"} />
                        </a>
                    </div>

                    <div className="modal-body nopad">
                        <div className="modalBody actionPopup">
                            <table className="table table-responsive table-striped table-borderless">
                                <thead>
                                    <tr>
                                        <th width="35%"/>
                                        <th width="20%" className="align-center">Buy</th>
                                        {giftingAsset &&
                                            <th width="19.5%" className="align-center">Gift</th>
                                        }
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>Downloads</td>
                                        <td className="align-center">{'Unlimited'}</td>
                                        {giftingAsset &&
                                            <td className="align-center">{shareasset && shareasset.gifting && shareasset.gifting.downloadLimit ? shareasset.gifting.downloadLimit : '-'}</td>
                                        }

                                    </tr>
                                    <tr>
                                        <td>Devices</td>
                                        <td className="align-center">{'Unlimited'}</td>
                                        {giftingAsset &&
                                            <td className="align-center">{shareasset && shareasset.gifting && shareasset.gifting.deviceLimit ? shareasset.gifting.deviceLimit : '-'}</td>
                                        }
                                    </tr>
                                    <tr>
                                        <td>Price</td>
                                        <td className="align-center"><strong>{shareasset && shareasset.availability && shareasset.availability.plan && shareasset.availability.plan.planPrice ? '$' + shareasset.availability.plan.planPrice : '-'}</strong></td>
                                        {giftingAsset &&
                                            <td className="align-center">
                                                {shareasset && shareasset.gifting && shareasset.gifting.contentPrice &&
                                                    <select name="giftPrice" className="form-control buy-dd" onChange={e => self.onChange(e)}>
                                                        <option value="">Select</option>
                                                        {
                                                            Object.entries(shareasset.gifting.contentPrice).map((contentPriceKey, contentPriceValue) => {
                                                                if (contentPriceValue === 0) return null;
                                                                return (<option key={contentPriceKey} value={contentPriceKey}>{contentPriceKey.toUpperCase()} {priceFormat(contentPriceValue, 'USD', 'en-US')} </option>)
                                                            })
                                                        }
                                                    </select>
                                                }
                                            </td>}


                                    </tr>

                                    <tr>
                                        <td/>
                                        <td className="align-center"><a href="#!" onClick={e => self.subscriptionClick(e, shareasset.availability.plan.planId, shareasset.availability.plan.planPrice)} className={shareasset && shareasset.availability && shareasset.availability.plan && shareasset.availability.plan.planPrice ? "buy_button" : "buy_button disabledTabs"}>SELECT</a></td>

                                        {giftingAsset &&
                                            <td className="align-center"><a href="#!" onClick={e => self.giftChange(e, shareasset)} className={shareasset && shareasset.gifting && shareasset.gifting.contentPrice && self.state.giftPrice ? "buy_button" : "buy_button disabledTabs"}>SELECT</a></td>
                                        }

                                        {/* <td className="align-center"><a href="javascript:void(0)" onClick={e => self.subscriptionClick(e, shareasset.availability.plan.planId, shareasset.availability.plan.planPrice)} className={shareasset && shareasset.availability && shareasset.availability.buy && shareasset.availability.buy.price ? "buy_button" : "buy_button disabledTabs"}>SELECT</a></td> */}
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="modal-footer nopad nobord"/>
                </Modal>


            </div>

        )
    }
}

const mapState = ({ assetinfo }) => ({ assetinfo });
const mapDispatch = { priceFormat };
export default connect(mapState, mapDispatch)(BuyOptionsPopupMaster);
