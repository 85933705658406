 import React, { Component } from 'react';
 import { connect } from 'react-redux';
 import Modal from 'react-modal';
 import {
     FacebookShareButton,
     TwitterShareButton,
 } from 'react-share';

 const customStyles = {
     content: {
         top: '50%',
         left: '50%',
         right: 'auto',
         bottom: 'auto',
         marginRight: '-50%',
         transform: 'translate(-50%, -50%)',
     }
 };

 class AssetInfoShare extends Component {
     constructor(props) {
         super(props);
         this.state = {
             iconikId: props.iconikId,
             shareTitle: props.shareTitle,
             shareClickPopup: false,
             isChecked: false,
             promourl: '',
             timeItem: '',
             starttime: "00:00:00",
             open: false,
             errors: {},
             asset: props.shareasset,
             isembed: false,
             copyLink: false
         };
         this.onShareClick = this.onShareClick.bind(this);
         this.toggleChange = this.toggleChange.bind(this);
         this.handleChange = this.handleChange.bind(this);
         this.setOpen = this.setOpen.bind(this);
     }
 
     onShareClick() {
         let self = this;
         self.setState({ shareClickPopup: true });
     }
 
     toggleChange(e) {
         this.setState({
             isChecked: !this.state.isChecked,
             promourlTime: undefined,
         });
     }
     toggleIsEmbedChange(name) {
         this.setState({
             isembed: false,
             copyLink: false,
             [name]: !this.state[name],
         });
     }
     handleCopy(e,text) {
        e.stopPropagation();
        e.preventDefault();
        const { title, item} = this.props;
        const link = `${window.location.origin}${window.location.pathname.replace('meeting', 'join')}?action=join&id=${item.event_id}&room=${title}`;
         this.textArea.select();
         var textField = document.createElement('textarea')
         textField.innerText = link
         document.body.appendChild(textField)
         document.execCommand('copy');
         textField.remove();
     }
     handleEmbedCopy(text) {
         this.embedtextarea.select();
         var textField = document.createElement('embedtextarea')
         textField.innerText = text
         document.body.appendChild(textField)
         document.execCommand('copy');
         textField.remove();
     }
 
     handleChange(e) {
         let self = this;
         self.setState({
             timeItem: e.target.value,
         });
     }
     setOpen(open) {
         let self = this;
         self.setState({ open: !self.state.open });
     };
 
     render() {
         const { isembed, copyLink } = this.state;
         const { shareClickPopup, closeModal, title, item} = this.props;
         const link = `https://meetings.envoi.live${window.location.pathname.replace('meeting', 'join')}?action=join&id=${item.event_id}&room=${title}&appName=${window.app.appName}`;
         let copyUrl = encodeURI(link);
         const Title = window.site.config.siteTitle || "";
         return (
             <div id="desc_btns">
 
 
                 <Modal
                     isOpen={shareClickPopup}
                     style={customStyles}
                     contentLabel="Example Modal"
                     className="share_modal custom_modal u-share-pop-new">
                     <div className="pop_hed">
                         <h2>
                             <button onClick={e => closeModal(e)} className="closeBtn img-15">
                                 <img src={window.site.config.imagesAssetResourcesPrefix +  window.site.themePrefix + "back_aro2.png" + window.site.config.imageSuffix} alt=""/> Back to video
                             </button>
                         </h2>
                        
                         <div className="modalBody">
                             <div className="u-border-bottom">
                              
 
                                     <div className="listIcon">
                                         <FacebookShareButton
                                             url={String(copyUrl)}
                                             subject={Title}>
                                             <h3><button className="u-sh-img"><img src={window.site.config.imagesAssetResourcesPrefix +  window.site.themePrefix +"socialicons/envoi_facebook.png?w=60"} alt=""/></button></h3>
                                         </FacebookShareButton>
                                     </div>
                                     <div className="listIcon">
                                         <TwitterShareButton
                                             url={String(copyUrl)}
                                             title={Title} >
                                             <h3><button className="u-sh-img"><img src={window.site.config.imagesAssetResourcesPrefix +  window.site.themePrefix + "socialicons/envoi_twitter.png?w=60"} alt=""/></button></h3>
                                         </TwitterShareButton>
                                     </div>
 
                                     <div className="listIcon">
                                         <h3><button onClick={e => this.toggleIsEmbedChange('copyLink')} className="u-sh-img"><img src={window.site.config.imagesAssetResourcesPrefix +  window.site.themePrefix + "socialicons/envoi_link.png?w=60"} alt=""/></button></h3>
                                     </div>
 
                                     <div className="listIcon">
                                         <h3><button onClick={e => this.toggleIsEmbedChange('isembed')} className="u-sh-img"><img src={window.site.config.imagesAssetResourcesPrefix +  window.site.themePrefix + "socialicons/envoi_coding.png?w=60"} alt=""/></button></h3>
                                     </div>
 
                            
 
                           
                             </div>
                             {isembed && <div className=" mt-4">
                             <h4 className="embed_title">Embed</h4>
                                     <div className="form-group u-copy-main-bl">
                                   
                                         <textarea type="text" className="form-control" placeholder="Promo Url" ref={(embedtextarea) => this.embedtextarea = embedtextarea}
                                             value={'<iframe width="100%" height="100%" src=' + copyUrl + ' frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>'} readOnly />
                                         <button className="u-copy-cont copy2" onClick={e => this.handleEmbedCopy('<iframe width="100%" height="100vh" src=' + copyUrl + ' frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>')}>Copy</button>
                                     </div>
                                    
                                 </div>}
 
                                 {copyLink && <div className=" mt-4">
                                 <h4 className="embed_title">Link</h4>
                                     <div className="form-group u-copy-main-bl">
                                    
                                         <input type="text" className="form-control" placeholder="Promo Url" ref={(textarea) => this.textArea = textarea} value={copyUrl} readOnly />
                                         <button className="u-copy-cont" onClick={e => this.handleCopy(e,copyUrl)}>Copy</button>
                                     </div>
                                     {/* <hr className="hr-clr-u mt-4" /> */}
                                 </div>}
                         </div>
                     </div>
                 </Modal>
             </div>
 
         )
     }
 }
 
 const mapState = ({ assetinfo }) => ({ assetinfo });
 export default connect(mapState, null)(AssetInfoShare);
