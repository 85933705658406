import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import * as config from '../../config';
import { connect } from 'react-redux';
import { DefaultModality, MeetingSessionStatusCode } from 'amazon-chime-sdk-js';

// Components
import VideoPlayer from '../videoPlayer/VideoPlayer';
import Chat from '../chat/Chat';
import Controls from './Controls';
import Settings from './Settings';
import LocalVideo from './LocalVideo';
import RemoteVideoGroup from './RemoteVideoGroup';
import Error from './Error';
import { getEventInfo } from '../../redux/reducers/reducer';
// Styles
import './ChimeWeb.css';
import AlertPopup from '../payment/AlertPopup';
class Meeting extends Component {

  state = {
    meetingStatus: null, // Loading, Success or Failed
    showSettings: false,
    eventInfo: {},
    showError: false,
    errorMsg: '',
    playbackURL:'',
    showContentShare: false,
    showMessage:false,
    playerType: config.DEFAULT_PLAYER_TYPE,
    eventData:'',
    currentTime:0,
  }

  constructor() {
    super();
    this.baseHref = config.BASE_HREF;
    this.ssName = '';

    this.audioElementRef = React.createRef();
    this.myVideoElement = React.createRef();
    this.contentShareElementRef = React.createRef();
    this.okClick = this.okClick.bind(this);
    this.playerTimeChanged = this.playerTimeChanged.bind(this);
  }

  componentDidMount() {

    const contentShareObserver = {
      videoTileDidUpdate: tileState => {
        // Ignore a tile without attendee ID and videos.
        if (!tileState.boundAttendeeId || !tileState.isContent) {
          return;
        }

        const meetingSession = this.props.chime && this.props.chime.meetingSession;
        const yourAttendeeId = meetingSession.configuration.credentials.attendeeId;

        // tileState.boundAttendeeId is formatted as "attendee-id#content".
        const boundAttendeeId = tileState.boundAttendeeId;

        // Get the attendee ID from "attendee-id#content".
        const baseAttendeeId = new DefaultModality(boundAttendeeId).base();
        if (baseAttendeeId === yourAttendeeId) {
          console.log('You called startContentShareFromScreenCapture');
        }
        try {
          const videoElement = this.contentShareElementRef.current;
          this.props.chime && this.props.chime.audioVideo && this.props.chime.audioVideo.bindVideoElement(tileState.tileId, videoElement);
          this.setState({ showContentShare: true })
        } catch (error) {
          console.error('Meeting.videoTileDidUpdate', { error });
        }

      },

      contentShareDidStart: () => {
        console.log('Screen share started');
        this.setState({ showContentShare: true })
      },

      contentShareDidStop: () => {
        // Chime SDK allows 2 simultaneous content shares per meeting.
        // This method will be invoked if two attendees are already sharing content
        // when you call startContentShareFromScreenCapture or startContentShare.
        console.log('Screen share stopped');
        this.setState({ showContentShare: false })
      }
    };


    const start = async () => {
      try {

        const qs = new URLSearchParams(this.props.location.search);
        const room = qs.get('room').replace(/\s/g, '');
        // const room ='test'
        this.ssName = `chime[${room}]`;
        if (!room || !sessionStorage.getItem(this.ssName)) {
          this.props.history.push(`${this.baseHref}/`);
        }

        const ssData = JSON.parse(sessionStorage.getItem(this.ssName));
        if (config.DEBUG) console.log(ssData);
        window.videoStreamURL = ssData.playbackURL;
        this.username = ssData.username;
        this.title = ssData.title;
        this.role = ssData.role;
        this.item = ssData.item;
        

        if (!ssData.joinInfo) {
          this.joinInfo = await this.props.chime.createRoom(this.role, this.username, this.title, ssData.playbackURL);
          const data = {
            ...ssData,
            joinInfo: this.joinInfo
          }
          sessionStorage.setItem(this.ssName, JSON.stringify(data));
          this.playbackURL = this.joinInfo && this.joinInfo.PlaybackURL;
          //this.setState({playbackUrl: this.playbackUrl});
        } else {
          // Browser refresh
          this.joinInfo = ssData && ssData.joinInfo;
          this.playbackURL = ssData && ssData.joinInfo && ssData.joinInfo.PlaybackURL;
          //this.setState({playbackUrl: this.playbackUrl});
          if(this.username){
            await this.props.chime && this.props.chime.reInitializeMeetingSession(this.joinInfo, this.username);
          }
          
        }
        if(ssData.item && ssData.item.event_id){
          this.props.getEventInfo(ssData.item.event_id);
         }

        this.setState({ meetingStatus: 'Success' });

        this.props.chime && this.props.chime.audioVideo && this.props.chime.audioVideo.addObserver({
          audioVideoDidStop: async (sessionStatus) => {
            if (sessionStatus.statusCode() === MeetingSessionStatusCode.AudioCallEnded) {
              const whereTo = `${this.baseHref}/${this.role === 'host' ? '' : 'end'}`;
              this.props.chime.leaveRoom(this.role === 'host');
              this.props.history.push(whereTo);
            }
          }
        });

        this.props.chime && this.props.chime.audioVideo && this.props.chime.audioVideo.addContentShareObserver(contentShareObserver);
        this.props.chime && this.props.chime.audioVideo && this.props.chime.audioVideo.addObserver(contentShareObserver);

        await this.props.chime.joinRoom(this.audioElementRef.current);
      } catch (error) {
        // eslint-disable-next-line
        console.error(error);
        this.setState({ meetingStatus: 'Failed' });
      }
    };

    start()
    .then(() => console.debug('Meeting started'));
  }
  componentDidUpdate(prevProps) {
    let self = this;
    const newProps = this.props;
    const newEventInfo = newProps.eventInfo;
    if (prevProps.eventInfo === newEventInfo) {
      return
    }
    this.setState({ eventInfo: newEventInfo });
    if (!newEventInfo) {
     // self.setState({ showMessage: true });
      return;
    }

    let playerType;
    switch(newEventInfo.defaultVideoPlayer) {
      case 'Envoi Player':
        playerType = 'envoi';
        break

      case 'Bitmovin':
        playerType = 'bitmovin'
        break

      default:
        playerType = newEventInfo.defaultVideoPlayer;

        
    }
    let playerUrl = '';
    if(newEventInfo.playerUrls && newEventInfo.playerUrls.length > 0){
      let playerUrls = newEventInfo.playerUrls[0];
      if (playerUrls && playerUrls.playback){
        let playbackUrls = playerUrls.playback;
        if (playbackUrls) {
          playerUrl = playbackUrls.hlsUrl || playbackUrls.progressiveUrl;
        }
      }
      //Added this to play for previous object 
      if (!playerUrl) {
        playerUrl = (playerUrls.hls) || (playerUrls.m3u8_Url) || (playerUrls.progressive) || (playerUrls.vdms_url) || (playerUrls.iconik_proxies_url) || (playerUrls.mp4url) || ( playerUrls.eluvio_url || playerUrls.Mp4_proxy_URL);
      }
    }
    if(newEventInfo.input && playerType === 'envoi'){
      this.setState({eventData: '&'+[newEventInfo.input.type]+'='+newEventInfo.input.id, playerType });
    }else if(newEventInfo && !newEventInfo.input && playerType === 'envoi' && newEventInfo.eventUrl){
      //this.setState({eventData:  '&asset='+[newEventInfo.eventUrl], playerType });
      this.setState({playbackURL:  newEventInfo.eventUrl,playerType,eventType:newEventInfo.eventType });
      // playerUrl = newEventInfo.eventUrl
      // console.log('newEventInfo.eventUrl',playerUrl)
    }
    else if(!playerUrl) {
      self.setState({
        showMessage: true
      })
    } else {
      self.playbackURL = playerUrl;
      self.setState({playbackURL: playerUrl}, () => {
        console.log('Meeting.js componentDidUpdate()', {
          playbackUrl: self.playbackUrl,
          joinInfo: self.joinInfo,
          username: self.username
        })
      });
    }
    this.setState({ playerType });
  }

  /*
   * Settings
   */

  openSettings = () => {
    this.setState({ showSettings: true });
  }

  closeSettings = () => {
    this.setState({ showSettings: false });
  }

  handleClick = (e) => {
    const { showSettings } = this.state;
    if (showSettings) {
      let node = e.target;
      let isModal = false;
      while (node) {
        if (node && node.classList && node.classList.contains('modal__el')) {
          isModal = true;
          break;
        }
        node = node.parentNode;
      }
      if (!isModal) {
        this.setState({ showSettings: false });
      }
    }
  }

  saveSettings = (playbackURL, currentAudioInputDevice, currentAudioOutputDevice, currentVideoInputDevice) => {
    this.setState({
      showSettings: false,
      currentAudioInputDevice,
      currentAudioOutputDevice,
      currentVideoInputDevice
    });
  }

  setMetadataId = (metadataId) => {
    this.setState({ metadataId });
  }

  setErrorMsg = errorMsg => {
    this.setState({ errorMsg, showError: true });
  }

  closeError = () => {
    this.setState({ showError: false });
  }
  okClick = async () => {
    let self = this;
    self.setState({ showmessage: false });
    await this.props.chime.leaveRoom(this.props.role === 'host');
    sessionStorage.removeItem(this.props.ssName);
    const whereTo = `${this.role === 'host' ? '/home' : '/join?room=' + this.props.title}`;
    this.props.history.push(whereTo);
}
playerTimeChanged = (time) => {
  this.setState({ currentTime: time });
}
  layout = () => {
    if (this.state.meetingStatus !== 'Success') {
      return;
    }

    return (
      <div className="app-grid" onClick={this.handleClick}>
        <div className="main-stage">
          <div className="cams pos-relative">
            <LocalVideo
              chime={this.props.chime}
              joinInfo={this.joinInfo}
            />
            <RemoteVideoGroup
              chime={this.props.chime}
              joinInfo={this.joinInfo}
            />
          </div>
          {this.state.showContentShare ?
            <video ref={this.contentShareElementRef} style={{ display: this.state.showContentShare ? 'block' : 'none'}} /> :
            (this.state.playbackURL || this.state.playerType === 'envoi') && <VideoPlayer
              setMetadataId={this.setMetadataId}
              videoStream={this.state.playbackURL}
              eventType={this.state.eventType}
              eventData={this.state.eventData}
              playerType={this.state.playerType}
              item={this.item}
              title={this.title}
              playerTimeChanged={this.playerTimeChanged}
            />}
          <Controls
            chime={this.props.chime}
            baseHref={this.baseHref}
            ssName={this.ssName}
            title={this.title}
            openSettings={this.openSettings}
            role={this.role}
            history={this.props.history}
            myVideoElement={this.myVideoElement}
            item={this.item}
            playerType={this.state.playerType}
            showContentShare={this.state.showContentShare}
          />
        </div>
        <Chat
          chime={this.props.chime}
          title={this.title}
          username={this.username}
          joinInfo={this.joinInfo}
          item={this.item}
          eventData={this.state.eventData}
          eventInfo={this.state.eventInfo}
          currentTime={this.state.currentTime}
        />
        {this.state.showMessage &&
          <AlertPopup  message="Sorry, this video is not available for viewing yet." closePopup={this.okClick} title='NOT AVAILABLE' imagePath="images/states_of_applications/warning_gray.png" showClose={true}/>}
        {this.state.showSettings && (
          <Settings
            chime={this.props.chime}
            joinInfo={this.joinInfo}
            saveSettings={this.saveSettings}
          />
        )}
      </div>
    )
  }

  render() {
    return (
      <React.Fragment>

        <audio ref={this.audioElementRef} style={{ display: 'none' }} />

        {this.layout()}

        {this.state.showError && (
          <Error
            closeError={this.closeError}
            errorMsg={this.state.errorMsg}
          />
        )}
      </React.Fragment>
    )
  }
}

Meeting.propTypes = {
  chime: PropTypes.object
};
const mapState = ({eventInfo }) => ({eventInfo});
const mapDispatch = {getEventInfo};
export default  withRouter(connect(mapState, mapDispatch)(Meeting));
//export default withRouter(Meeting);
