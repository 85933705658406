import React, { Component } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import { browserHistory } from 'react-router';
import * as config from '../config';
// import './App.css';
import { connect } from 'react-redux';
import ChimeSdkWrapper from './chime/ChimeSdkWrapper';
import { getWebsiteDefault, getLocation} from '../redux/reducers/reducer';
import Home from './chimeWeb/Welcome';
import Join from './chimeWeb/Join';
import Meeting from './chimeWeb/Meeting';
import End from './chimeWeb/End';
import LandingPage from './authentication/LandingPage';
import { UserProvider } from './userContext';
import AddDevice from "./authentication/AddDevice";
import CreateAnAccount from './authentication/CreateAnAccount';
import AlertPopup from './AlertPopup';
import DonationPayment from "./payment/DonationPayment";
import Payment from './payment/buyAsset';
import SuccessBought from './SuccessBought';
import Loader from './Loader';
import SubscriptionPayment from './authentication/SubscriptionPayment';
import EventInfo from './chimeWeb/EventInfo';

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoaded: false,
    }
  }
  componentDidMount() {
    this.props.getWebsiteDefault();
  }
  async componentDidUpdate(prevProps) {
    let nextProps = this.props
    if (nextProps.websiteDefaultData !== prevProps.websiteDefaultData) {
      // let siteSettings = nextProps.websiteDefaultData || {};
      // await window.processSiteSettings(siteSettings);
      let siteConfig = window.site.config;
      let imageSuffix = siteConfig.imageSuffix;
      let imagePrefix = siteConfig.imagePrefix

      // let siteConfig = window.site.config;
      // let themeStyle = ((siteConfig.screening || {}).themeStyle || '');
      // let  posterUrl = imagePrefix + "screening/images/movie_Poster.jpg" + imageSuffix;
      // let imagePrefix = siteConfig.imagePrefix = siteConfig.appResourcesUrl + siteConfig.appResourcesPath;
      // let imageSuffix = siteConfig.imageSuffix = imageSuffix;
      // siteConfig.imagesPath = siteConfig.imagePrefix;
      // siteConfig.themePrefix = themeStyle ? 'images/' + themeStyle + '/' : 'images/';
      // siteConfig.imagesAssetResourcesPrefix = siteConfig.commonResourcesUrl + "screening/";
      // siteConfig.accountType = siteConfig.accountType || "";
      // siteConfig.paymentEnable = (siteConfig.subscription || {}).enable;
      // window.preLoader = siteConfig.imagePrefix + 'screening/images/preloader.png' + imageSuffix;
      //window.Title = siteConfig.siteTitle;
      //window.logoImage = window.imagesPath + "screening/images/headerlogo.png" + imageSuffix;

      this.setState({imagePrefix, imageSuffix, siteConfig, isLoaded: true})
      this.props.getLocation()
    }
  }
  render() {
    const { imagePrefix, siteConfig, isLoaded} = this.state;
    const chime = new ChimeSdkWrapper();
    const baseHref = config.BASE_HREF;

    return (isLoaded ?
      <div className="App full-width full-height">
        <UserProvider value={{ imagePrefix, siteConfig }}>
          <Router history={browserHistory}>
            <Switch>
              <Route exact path="/end" >
                <End />
              </Route>
              <Route path={`${baseHref}/meeting`}>
                <Meeting
                  chime={chime}
                />
              </Route>
              <Route path={`${baseHref}/join`}>
                <Join
                  chime={chime}
                />
              </Route>
              <Route path={`${baseHref}/home`}>
                <Home
                  chime={chime}
                />
              </Route>
              <Route path="/create" component={CreateAnAccount} />

              <Route path={`${baseHref}/adddevice`}>
                <AddDevice
                  chime={chime}
                />
              </Route>
              <Route path="/eventinfo" component={EventInfo} />
              <Route path="/payment/:assetid" component={Payment} />
              <Route path="/paymentdonation" component={DonationPayment} />
              <Route path="/boughtsuccess" component={SuccessBought} />
              <Route path="/subpayment/:planId" component={SubscriptionPayment} />
              <Route path={`${baseHref}`}>
                <LandingPage
                  chime={chime}
                />
              </Route>
            </Switch>
          </Router>
        </UserProvider>
        <AlertPopup />
      </div> : <Loader />
    );
  }
}
const mapState = ({ websiteDefaultData }) => ({ websiteDefaultData });
const mapDispatch = { getWebsiteDefault, getLocation};
export default connect(mapState, mapDispatch)(App);

