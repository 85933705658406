/***
**Module Name:  Payment
**File Name :  Payment.js
**Project :      CALENDOW
**Copyright(c) : X Platform Consulting.
**Organization : Peafowl Inc
**author :  Hari
**author :  Rajesh
**license :
**version :  1.0.0
**Created on :
**Last modified on:
**Description : Payment page functionality and html code
*/
//export default App;
import React, { Component } from 'react';
import { connect } from 'react-redux';
import axios from "axios";
import NavLink from '../NavLink';
import { getAssetInfo,  getPricing, priceFormat } from '../../redux/reducers/reducer';
import windowSize from 'react-window-size';
import braintree from "braintree-web";
import AlertPopup from './AlertPopup';
import $ from 'jquery';
var urlParams = new URLSearchParams(window.location.search);
var partnercode;
var customer_address = {};
var addressVisible = localStorage.getItem("adv");
let token = localStorage.getItem('token');

class payment extends Component {
    constructor(props) {
        super(props);
        token = localStorage.getItem('token');
        if (urlParams.has('partnercode')) {
            partnercode = urlParams.get('partnercode');
        }

        var isLoggedIn = false;
        if (!token) {
            window.location = '/';
        } else {
            isLoggedIn = true;
        }
        var adr = JSON.parse(localStorage.getItem("adr"));
        var firstname = '';
        var lastname = '';
        var email = '';
        var phone = '';
        if (localStorage.getItem("firstname") && localStorage.getItem("firstname") !== null && localStorage.getItem("firstname") !== undefined) {
            firstname = localStorage.getItem("firstname")
        }
        if (localStorage.getItem("lastname") && localStorage.getItem("lastname") !== null && localStorage.getItem("lastname") !== undefined) {
            lastname = localStorage.getItem("lastname")
        }
        if (localStorage.getItem("email") && localStorage.getItem("email") !== null && localStorage.getItem("email") !== undefined) {
            email = localStorage.getItem("email")
        }
        if (localStorage.getItem("phone") && localStorage.getItem("phone") !== null && localStorage.getItem("phone") !== undefined) {
            phone = localStorage.getItem("phone");
        }
        this.state = {
            data: [],
            loader: false,
            login: isLoggedIn,
            selected: '',
            navigationtab: '',
            isTop: true,
            partnercode: partnercode,
            tv_show_name: this.props.match && this.props.match.params && this.props.match.params.tv_show_name? this.props.match.params.tv_show_name:'',
            assetid: this.props.match && this.props.match.params && this.props.match.params.assetid ? this.props.match.params.assetid:'',
            channelcode: this.props.match && this.props.match.params && this.props.match.params.channelcode ? this.props.match.params.channelcode:'',
            error: '',
            errors: {},
            streetAddress: '',
            locality: '',
            extendedAddress: '',
            countryCodeAlpha2: '',
            region: '',
            showAsset: [],
            postalCode: '',
            checked: false,
            adr: adr,
            customer_address: {},
            countrydefault: "",
            regiondefault: "",
            firstname: firstname,
            lastname: lastname,
            email: email,
            phone: phone,
            //modifiedavatar: imageprefix + 'assets/images/profiles/default/avatar1.jpg' + imagesuffix,
            addressVisible: addressVisible,
            cardholderName: firstname + ' ' + lastname,
            assetPaid: false,
            paymentError: false,
            errMessage: null,
            freeAsset: false,
            assetinfo: JSON.parse(localStorage.getItem('eventInfo')),
        };

        this.handleOptionChange = this.handleOptionChange.bind(this);
        this.handleTextChange = this.handleTextChange.bind(this);
        this.onScroll = this.onScroll.bind(this);
        this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
        this.handleClose = this.handleClose.bind(this);
        // this.loadBraintree = this.loadBraintree.bind(this);
    }
    onScroll(isTop) {
        this.setState({ isTop });
    }
    componentDidMount() {
        var self = this;
        
        self.braintreeSetup()
        
        var urlParams = new URLSearchParams(window.location.search);

        let typeasset = false;
        if (urlParams.has('t')) {
            typeasset = true;
        }
        if (!typeasset && self.state.assetid) {
            this.props.getAssetInfo(self.state.assetid, token);
            // localStorage.setItem("assetId", self.state.assetid);
        } else {
            // localStorage.setItem("channelcode", self.state.assetid);
            axios.post(window.site.screeningApiBaseUrl + '/assetinfo?appname=' + window.app.appName + '&token=' + token + '&channelcode=' + self.state.assetid + '&env=' + window.app.appEnvName)
                .then(function (response) {
                    let availability = (response && response.data && response.data.result && response.data.result.availability) || {}
                    if (availability.type === "Free" || availability.free) {
                        self.setState({ freeAsset: true });
                        localStorage.removeItem("a");
                    } else if (response && response.data && response.data.result && response.data.result.buy) {
                        self.setState({ assetPaid: true });
                        localStorage.removeItem("a");
                    } else if (response && response.data && response.data.result) {
                        let channelInfo = response.data.result;
                        let arrayAssets = [];
                        let contentType = localStorage.getItem("contentType");
                        let actionType = localStorage.getItem("action") || "buy";
                        let availability = (channelInfo && channelInfo.availability) || {};
                        let buyplans = availability.buyplans;
                        let channelprice = (buyplans[actionType] && buyplans[actionType].contentPrice && buyplans[actionType].contentPrice[contentType]) || 0;
                        let obj = {
                            "assetid": channelInfo.channelcode,
                            "assetName": channelInfo.channelname,
                            "assetname": channelInfo.channelname,
                            "single_price": channelprice,
                            "userassetType": "channel"
                        }
                        localStorage.setItem("channelcode", channelInfo.channelcode);
                        arrayAssets.push(obj)
                        self.setState({ assetinfo: channelInfo, arrayAssets, assetprice: channelprice, assetname: channelInfo.channelname, contentType: contentType });
                    }
                })
                .catch(function (error) {
                });
        }

        document.addEventListener('scroll', () => {
            var isTop;
            if (window.scrollY < 50) {
                isTop = true;
            } else {
                isTop = false;
            }
            if (isTop !== this.state.isTop) {
                if (this.refs.headermenu) {
                    this.onScroll(isTop);
                }
            }
        });

       
        

        customer_address["countryCodeAlpha2"] = self.state.countrydefault;
        customer_address["region"] = self.state.regiondefault;
        customer_address["addresstype"] = "billing";

        if (self.state.adr) {
            self.state.adr["addresstype"] = "billing";
        }


        this.setState({ customer_address, adr: self.state.adr });


         if(this.state.firstname === 'undefined') {this.setState({ firstname: '' })}
         if(this.state.lastname === 'undefined') { this.setState({ lastname: '' }) }
         if(this.state.email === 'undefined') {this.setState({ email: '' })}
         if(this.state.phone === 'undefined') { this.setState({ phone: '' }) }


        // document.getElementById("monthly").checked = true;

        document.getElementById("credit").checked = true;


    }
    componentDidUpdate(prevProps) {
        let actionType = localStorage.getItem("action") || "buy";
        let self = this;
        if (prevProps.assetInfo && prevProps.assetInfo !== this.props.assetInfo) {
            let assetinfo = this.props.assetInfo;
            //assetInfo = JSON.parse(localStorage.getItem('eventInfo'));
            
            if (actionType && actionType !== "rent" && assetinfo.buy) {
                localStorage.removeItem("a");
                this.setState({ assetPaid: true, assetinfo })
            } else {
                this.setState({ assetinfo });
            }
            let contentType = localStorage.getItem("contentType");
            let availability = (assetinfo && assetinfo.availability) || {}
            if (availability.type === "Free" || availability.free) {
                localStorage.removeItem("a");
                self.setState({ freeAsset: true });
            } else {
                let buyplans = availability.buyplans;
                let assetprice = (buyplans[actionType] && buyplans[actionType].contentPrice && buyplans[actionType].contentPrice[contentType]) || 0;
                var arrayAssets = []

                var obj = {
                    "assetid": assetinfo.assetid ? assetinfo.assetid :assetinfo.event_id,
                    "single_price": assetprice,
                    "assetName": assetinfo.assetname?assetinfo.assetname:assetinfo.title,
                    "assetname": assetinfo.assetname?assetinfo.assetname:assetinfo.title,
                    "userassetType": assetinfo.assetid ? "asset" : "event"
                }
                
                localStorage.setItem("assetId", assetinfo.assetid);
                arrayAssets.push(obj)
                this.setState({ arrayAssets, assetprice: assetprice, assetname: assetinfo.assetname?assetinfo.assetname:assetinfo.title, contentType: contentType });
            }
        }

        if (prevProps.assetPayment && prevProps.assetPayment !== this.props.assetPayment) {
            if (this.props.assetPayment.error) {
                self.setState({
                    paymentError: true,
                    errMessage: this.props.assetPayment.error,
                })
                if(this.props.assetPayment.error === 'event was already purchased'){
                    this.props.history.push(`/meeting?room=${self.state.assetinfo.title}`);
                }
             } else {
                this.props.history.push("/boughtsuccess")
            }
        }
    }
    braintreeSetup() {
        let self = this;
        let stylesConfig = {
            // Style all elements
            'input': {
                'font-size': '30px',
                'color': '#ffffff'
            },
            // Styling element state
            ':focus': {
                'color': '#ffffff'
            },
            '.valid': {
                'color': '#6e9e12'
            },
            '.invalid': {
                'color': 'red'
            },
            '@media screen and (max-width: 700px)': {
                'input': {
                    'font-size': '14px'
                }
            }
        }
        let fieldsConfig = {
            number: {
                selector: "#card-number",
                placeholder: "****************"
            },
            cvv: {
                selector: "#cvv",
                placeholder: "***"
            },
            expirationMonth: {
                selector: "#expiration-month",
                placeholder: "MM"
            },
            expirationYear: {
                selector: "#expiration-year",
                placeholder: "YY"
            },
            postalCode: {
                selector: "#postal-code",
                placeholder: "******"
            }
        }

        let submitBtn = document.getElementById('paySubmit');
        
        braintree.client.create({ authorization: window.site.clientToken }).then(function (client) {
            return braintree.hostedFields.create({
                client: client,
                styles: stylesConfig,
                fields: fieldsConfig
            });
        }).then(function (hostedFields) {
            if (hostedFields.tokenize()) {
                submitBtn.addEventListener('click', function (event) {
                    event.preventDefault();


                    hostedFields.tokenize().then(function (payload) {
                        // send payload.nonce to your server
                        const err = self.validate();
                        if (!err) {
                            let actionType = localStorage.getItem("action") || "buy";
                            let assetprice = self.state.assetprice;
                            let arrayAssets = self.state.arrayAssets;
                            let itemType = "asset";
                            localStorage.setItem("a", 'asset');
                            let sku = self.state.assetinfo && self.state.assetinfo.quickbooksId;
                            if (arrayAssets.length > 0 && arrayAssets[0].userassetType === "channel") {
                                itemType = "channel";
                                localStorage.setItem("a", 'channelasset');
                            }
                            let isSingleEventPurchase = false;
                            if(self.state.assetinfo.event_id){
                                localStorage.setItem("a", 'event');
                                itemType = "event";
                                isSingleEventPurchase = true
                            }
                            let envoisku = self.state.assetinfo && self.state.assetinfo.envoisku
                            self.props.getPricing(token, payload.nonce, assetprice, arrayAssets, self.state.optionalAddress, self.state.optionalCity, self.state.contentType, itemType, actionType, sku, self.state.cardholderName, envoisku, isSingleEventPurchase);
                            

                        }

                    }).catch(function (err) {
                        self.setState({ errMessage: err.message });

                    });
                });
            }
        });

    }

    addressChange(e) {
        
        const name = e.target.name;
        const value = e.target.value;
        
        this.setState({ [name]: value });
    }
    handleTextChange(e) {
        var self = this;
        if (!!this.state.errors[e.target.name]) {
            let errors = Object.assign({}, this.state.errors);
            delete errors[e.target.name];
            customer_address[e.target.name] = e.target.value;
            this.setState({
                [e.target.name]: e.target.value,
                errors,
                customer_address
            });
        } else {
            customer_address[e.target.name] = e.target.value;
            self.setState({
                [e.target.name]: e.target.value,
                customer_address
            });
        }
    }
    handleCheckboxChange() {
        if (this.state.adr && this.state.adr.postalCode) {
            this.setState({ checked: !this.state.checked, postalCode: this.state.adr.postalCode });
        } else {
            this.setState({ checked: !this.state.checked })
        }
    }
    handleOptionChange(e) {
        var self = this;
        self.setState({
            [e.target.name]: e.target.value
        });
    }
    validate() {
        let isError = false;
        let errors = {};

        if (this.state.cardholderName === undefined || this.state.cardholderName === '') {
            isError = true;
            errors.cardholderName = "Enter Cardholder Name";
        }

        if (!this.state.optionalAddress) {
            isError = true;
            errors.optionalAddress = "Address Required";
        }
        if (!this.state.optionalCity) {
            isError = true;
            errors.optionalCity = "City Required";
        }
        if (this.state.checked) {
            this.setState({ errors, streetAddress: this.state.adr.streetAddress, postalCode: this.state.adr.postalCode, locality: this.state.adr.locality, extendedAddress: this.state.adr.extendedAddress, countryCodeAlpha2: this.state.adr.countryCodeAlpha2, region: this.state.adr.region });
        } else {
            this.setState({ errors });
        }

        return isError;
    }
    paymentOption(e) {

        var optionId = e.target.id;
        $(".paymentopt").addClass("hidediv");

        if (optionId === "paypal") {
            $("#" + optionId + "-container").removeClass("hidediv");
        } else {
            $("#" + optionId + "card").removeClass("hidediv");
        }
    }
    handleClose(e) {
        e.stopPropagation();
        e.preventDefault();
        localStorage.removeItem("action");
        localStorage.removeItem("contentType");
        localStorage.removeItem("assetId");
        localStorage.removeItem("a");
        this.props.history.push("/home")

        // if(browserHistory.goBack){
        //     browserHistory.goBack
        // }else {
        //     browserHistory.push(defaultRoute)
        // }
    }

    render() {
        let self = this;
        const { priceFormat } = self.props;
        const { assetinfo, assetPaid, assetprice, contentType, assetname, paymentError, errMessage, freeAsset } = this.state;
        var color = { color: "#f00" }
        let actionType = localStorage.getItem("action") || "buy";;
        return (

            <div>

                <div id="overlay" className="hidediv">

                    <div className="preloader">
                        <div className="loftloader-wrapper pl-frame">
                            <div className="loader">
                                <div className="spark"></div>
                                <img alt="preloader" src={window.site.preLoader} />
                                <span></span>
                            </div>
                        </div>
                    </div>
                </div>

                <div ref="headermenu" className={this.state.isTop ? 'header_det pmt_dt' : 'header_det pmt_dt up'}>
                    <div className="wrapper">
                        <div className="logo"><img alt="preloader" src={window.site.logoImage} /></div>
                        {/* <a href="#" onClick={browserHistory.goBack} className="back"></a> */}
                        <button onClick={e => self.handleClose(e)} className="cls"/>
                    </div>
                </div>

                <div className="payment_bg new-ubg">
                    {/* <img src={payment_bg} /> */}
                </div>
                <div className="photos_wrapper">
                    <h2>{(actionType && actionType.toUpperCase()) || 'BUY'}</h2>
                    <h4 className="sub-text">ENTER PAYMENT INFO</h4>
                    <div className="payment_wrp4 u-pay-bl">
                        <div className="pmt_lft1 mob-u-pmt_lft">
                            <h4>{(actionType && actionType.toUpperCase()) || 'BUY'}</h4>
                            <div className="row ast_infoo1">
                                <div className="col-md-12">
                                    {assetinfo && assetinfo.envoisku && <p><label style={{ 'width': '80px' }}>SKU : </label> {assetinfo && assetinfo.envoisku.toUpperCase()}</p>}
                                    <p><label style={{ 'width': '80px' }} >Title : </label> {assetname && assetname.substring(0, 25)}{assetname && assetname.length > 25 ? "....." : ""}</p>
                                    <p><label style={{ 'width': '80px' }}>Quality : </label> {contentType && contentType.toUpperCase()}</p>
                                    <p><label style={{ 'width': '80px' }}>Price : </label> {assetinfo && priceFormat(assetprice, 'USD', 'en-US')}</p>
                                </div>
                            </div>

                        </div>
                        <div className="pmt_rgt1">

                            <form id="cardForm" method="post">

                                <div className="pmt_blk1 pt-0">
                                    <p className="ps3 text-left">ACCOUNT CREATED FOR: {localStorage.getItem("email")}</p>

                                </div>
                                <div className="pmt_blk1">
                                    <h3>PAYMENT METHOD</h3>

                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="form-group payment-mode"><input type="radio" id="credit" name="paymenttype" value="creditcard" onClick={e => this.paymentOption(e)} /><label>CREDIT CARD</label></div>

                                        </div>
                                        <div className="col-md-6 hidediv">
                                            <div className="form-group payment-mode"><input type="radio" id="paypal" name="paymenttype" value="paypal" onClick={e => this.paymentOption(e)} /><label>PAYPAL</label></div>

                                        </div>
                                    </div>
                                    <div className="cc_det">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <label>CARDHOLDER NAME</label>
                                                    <input type="text" className="hosted-field" value={this.state.cardholderName || ""} name="cardholderName" onChange={e => this.handleTextChange(e)} />
                                                    <span className="errormsg" >{this.state.errors.cardholderName}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="cc_det paymentopt" id="creditcard">

                                        <div className="row">


                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <label>CARD NUMBER</label>
                                                    <div id="card-number" className="hosted-field"></div>
                                                </div>
                                            </div>
                                            {/* <div className="col-md-4">
                                                <div className="form-group">
                                                    <label>EXPIRATION</label>
                                                    <div id="expiration-date" className="hosted-field"></div>
                                                </div>
                                            </div> */}
                                            <div className="col-md-4">
                                                <div className="form-group inline-st">
                                                    <label className="pmt_lbl">EXPIRATION</label>
                                                    <div id="expiration-month" className="hosted-field"></div>
                                                    <div id="expiration-year" className="hosted-field"></div>
                                                </div>
                                            </div>

                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <label>CVV</label>

                                                    <div id="cvv" className="hosted-field"></div>
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <label>ZIP CODE</label>

                                                    <div id="postal-code" className="hosted-field"></div>
                                                </div>
                                            </div>

                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <label>ADDRESS <span style={{ 'color': '#afafaf' }}></span></label>
                                                    <input type="text" value={this.state.optionalAddress} placeholder="Street address, P.O. box, Company name, C/o" name="optionalAddress" onChange={e => this.addressChange(e)} />
                                                    <span className="errormsg" >{this.state.errors.optionalAddress}</span>
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <label>CITY <span style={{ 'color': '#afafaf' }}></span></label>
                                                    <input type="text" value={this.state.optionalCity} placeholder="City" name="optionalCity" onChange={e => this.addressChange(e)} />
                                                    <span className="errormsg" >{this.state.errors.optionalCity}</span>
                                                </div>
                                            </div>

                                        </div>


                                    </div>
                                    <div id="paypal-container" className="paymentopt cc_det hidediv"></div>
                                </div>
                                


                                {paymentError && <p className="paymentError mb-2 mt-2" style={color}>Error: Your payment has been declined</p>}

                                {errMessage != null && <p className="paymentError mb-2 mt-2" style={color}>{errMessage}</p>}

                                <p className="free_trl">By selecting “Start {window.site.config.siteTitle} Account”, you agree to authorize this charge and to the &nbsp;
                                <NavLink to='/terms' target="_blank" className="tos">Terms of Service.</NavLink></p>
                                <div className="form-group">
                                    <input type="button" id="paySubmit" value="PAY" className="submit" />

                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                {assetPaid &&
                    <AlertPopup message="Sorry, You have already purchased this asset." closePopup={this.handleClose} title='ALREADY PURCHASED' imagePath="images/states_of_applications/warning_gray.png" showClose={false} />
                }

                {freeAsset &&
                    <AlertPopup message="You came to the wrong page." closePopup={this.handleClose} title='Opps!' imagePath="images/states_of_applications/warning_gray.png" showClose={false} />
                }
            </div>

        );
    }
}


const mapState = ({ address, assetInfo, assetPayment }) => ({ address, assetInfo, assetPayment });
const mapDispatch = { getAssetInfo, getPricing, priceFormat };
export default windowSize(connect(mapState, mapDispatch)(payment));
