import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import Modal from 'react-modal';
import { connect } from 'react-redux';
import AssetInfoShare from '../AssetInfoShare';
import renderHtml from 'html-react-parser';
import $ from 'jquery';
import _ from 'lodash';
import braintree from "braintree-web";
import Loader from '../Loader';
import {
  DefaultPromisedWebSocketFactory,
  DefaultDOMWebSocketFactory,
  FullJitterBackoff,
  ReconnectingPromisedWebSocket
} from 'amazon-chime-sdk-js';
import * as config from '../../config';
// Styles
import './Chat.css';
import { approvedAsset, getClips, createClip, giveVote} from '../../redux/reducers/reducer';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import moment from 'moment';
import axios from "axios";
let token = localStorage.getItem('token');
let lambdaUrl = window.site.screeningApiBaseUrl;
let appname = window.app.appName;
let environmentVariable = 'dev';
const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  }
};
let firstname = (localStorage.getItem("firstname") || '').substring(0, 1);
var customer_address = {};
class Chat extends Component {
  constructor(props) {
    super(props);

    this.WEB_SOCKET_TIMEOUT_MS = 10000;
    this.baseHref = config.BASE_HREF;
    this.state = {
      message: '',
      messages: [],
      connection: null,
      showPopup: false,
      isOpen: false,
      item: props.item,
      activeTab: 1,
      currentlyWatchingCount: 0,
      currentlyWatching:{},
      subClips:[],
      tabIndex:0,
      selectedFeed:'',
      creatingClip: false,
      errors: {},
      clipName:'',
      startTime:'',
      endTime:'',
      allClips: [],
      selectedSession: {},
      selectedSessionNo: 0,
      showVotingResults: false,
      showDonationPayment: false,
      successmessage: false,
      errMessage: null,
      showLoader: false,
    }
    this.chatRef = React.createRef();
    this.messagesEndRef = React.createRef();
    this.closeShareModal = this.closeShareModal.bind(this);
    this.openModal = this.openModal.bind(this);
    this.pickTime = this.pickTime.bind(this);
    this.KeyPress = this.KeyPress.bind(this);
    document.addEventListener('keydown', (e) => {
      this.KeyPress(e);
    });
  }

  KeyPress(e) {
    let self = this;
    var evtobj = window.event? window.event : e;
    if (evtobj.keyCode === 77 && evtobj.ctrlKey) {
      self.pickTime();
    }
  }
  componentDidMount() {
    let self = this;
    console.log('this.props.joinInfo',this.props.joinInfo)
    Modal.setAppElement('body');
    if(this.props.joinInfo){
      self.initChatConnection();
    }
    //
    this.props.chime && this.props.chime.subscribeToRosterUpdate(this.rosterCallback);
    
   console.log('here',this.state.item);
   if(this.state.item.voting && this.state.item.voting.isLive){
    this.setState({activeVoting: this.state.item.voting.sessions[0]});
    }
    if(this.state.item.donation && this.state.item.donation.isLive){
      this.setState({activeDonation: this.state.item.donation.sessions[0]});
      }
      if(this.state.item && this.state.item.voting){
      this.setState({ selectedSession: this.state.item.voting.sessions[0], selectedSessionNo: 0});
      }
  }
  rosterCallback = (newRoster) => {
    this.setState({ currentlyWatchingCount: Object.keys(newRoster).length, currentlyWatching:newRoster})
  }
  
  async initChatConnection() {
      const { Meeting, Attendee } = this.props && this.props.joinInfo;
    
    
    const messagingUrl = `${config.CHAT_WEBSOCKET}?MeetingId=${Meeting && Meeting.MeetingId}&AttendeeId=${Attendee && Attendee.AttendeeId}&JoinToken=${Attendee && Attendee.JoinToken}`
    const connection = new ReconnectingPromisedWebSocket(
      messagingUrl,
      [],
      'arraybuffer',
      new DefaultPromisedWebSocketFactory(new DefaultDOMWebSocketFactory()),
      new FullJitterBackoff(1000, 0, 10000)
    );

    if (config.DEBUG) console.log(connection);

    await connection.open(this.WEB_SOCKET_TIMEOUT_MS);

    connection.addEventListener('message', event => {
      const messages = this.state.messages;
      const data = event.data.split('::');
      const username = data[0];
      const message = data.slice(1).join('::'); // in case the message contains the separator '::'
      switch (message) {
        case "pause":
          if(window.player){
            window.player.pause();
          }
            break;
        case "play":
          if(window.player){
            window.player.play();
          }
            break;
        default:
            break;
    }
      messages.push({
        timestamp: Date.now(),
        username,
        message
      });

      this.setState({ messages });
    });

    if(window.app.storage.getItem('donated') && connection){
      const { username } = this.props;
      const data = `{
        "message": "sendmessage",
        "data": "${username}::donated ${window.app.storage.getItem('donated')}"
      }`;
        connection.send(data);
        window.app.storage.removeItem('donated')
    }
    this.setState({ connection });
    // if (this.chatRef) {
    //   this.chatRef.current.focus();
    // }

  }


  scrollToBottom = () => {
    this.messagesEndRef.current.scrollIntoView({ behavior: 'smooth' })
  }

  handleChange = e => {
    this.setState({ message: e.target.value })
  }
  postMessage = (e) => {
    e.stopPropagation();
    e.preventDefault();
    const { message, connection } = this.state;
      const { username } = this.props;
      if (message) {
        const data = `{
          "message": "sendmessage",
          "data": "${username}::${message.replace(/\\/g, '\\\\').replace(/"/g, '\\"')}"
        }`;
        if (connection) {
          connection.send(data);

        }
        this.setState({ message: '' });
      }
  }
  handleKeyDown = (e) => {
    if (e.keyCode === 13) { // keyCode 13 is carriage return
      this.postMessage(e);
  }
  }

  handleRoomClick = (e) => {
    e.stopPropagation();
    e.preventDefault();
    const { title, item } = this.props;
    const link = `${window.location.origin}${window.location.pathname.replace('meeting', 'join')}?action=join&id=${item.event_id}&room=${title}`;
    if (config.DEBUG) console.log(link);
    this.copyTextToClipboard(encodeURI(link));
  }

  setShowPopup = () => {
    // show popup message
    this.setState({ showPopup: true });

    // hide popup message after 2 seconds
    setTimeout(() => {
      this.setState({ showPopup: false })
    }, 2000);
  }

  copyTextToClipboard = text => {
    if (navigator.clipboard) {
      navigator.clipboard.writeText(text)
        .then(() => {
          this.setShowPopup();
          if (config.DEBUG) console.log('Room link copied to clipboard');
        }, (err) => {
          if (config.DEBUG) console.log('Could not copy text: ', err);
        });
    }
  }

  parseUrls = (userInput) => {
    var urlRegExp = /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_.~#?&//=]*)/g;
    let formattedMessage = userInput.replace(urlRegExp, (match) => {
      let formattedMatch = match;
      if (!match.startsWith('http')) {
        formattedMatch = `https://${match}`;
      }
      return `<a href=${formattedMatch} className="chat-line__link" target="_blank" rel="noopener noreferrer">${match}</a>`;
    });
    return formattedMessage;
  }

  renderMessages = () => {
    const { messages, currentlyWatchingCount } = this.state;
    return (

<div className="chat_block">
              <div className="activity_hed">
                <div className="hedText">
                  <h3>LIVE CHAT</h3>
                  <p className="flex_div"><span className="material-icons">person</span> {currentlyWatchingCount}</p>
                </div>

                <button className="wp_close" onClick={e => this.changeTab(e, 1)}><span className="material-icons">  close</span></button>

              </div>
              <div className="activity_content">
                <ul>
                {messages.map((message, i) => {
        let formattedMessage = this.parseUrls(message.message);
        let timeStamp = moment(message.timestamp).format("hh:mm a");
        let name = message.username.substring(0, 1);
        return (
                  <li key={i}><div className="name_thumb">{name}</div>
                    <p>{timeStamp} <span> <b> {message.username}</b> {renderHtml(formattedMessage)}</span></p>
                  </li>
                )
              })
            }
                </ul>
              </div>
            </div>

     
    )
  }

  donation = () => {
    this.setState({ isOpen: true })
  }

  closeModal = (e) => {
    e.stopPropagation();
    e.preventDefault();
    this.setState({ isOpen: false })
  }

  onChangePrice(price, priceMethod) {
    let self = this;
    self.setState({ price, priceMethod })
  }
  onChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    this.setState({ [name]: value, price: value })

  }
  continue = (item) => {
    let self = this;
    localStorage.setItem("e", "event");
    localStorage.setItem("eventId", item && item.event_id);
    localStorage.setItem("assetPrice", self.state.price);
    //  browserHistory.push("/paymentdonation?planId=true");
    this.props.history.push(`${this.baseHref}/paymentdonation?planId=true`);
  }
  showDonationPayment= (item) => {
    let self = this;
        
       if (self.state.price === "")
            self.setState({ price: 0 })
        

    self.setState({planid: true, showDonationPayment: !self.state.showDonationPayment}, () => self.braintreeSetup());

  }
  braintreeSetup=()=> {
    let self = this;
    let stylesConfig = {
        // Style all elements
        'input': {
            'font-size': '30px',
            'color': '#ffffff'
        },
        // Styling element state
        ':focus': {
            'color': '#ffffff'
        },
        '.valid': {
            'color': '#6e9e12'
        },
        '.invalid': {
            'color': 'red'
        },
        '@media screen and (max-width: 700px)': {
            'input': {
                'font-size': '14px'
            }
        }
    }
    let fieldsConfig = {
        number: {
            selector: "#card-number",
            placeholder: "****************"
        },
        cvv: {
            selector: "#cvv",
            placeholder: "***"
        },
        expirationMonth: {
            selector: "#expiration-month",
            placeholder: "MM"
        },
        expirationYear: {
            selector: "#expiration-year",
            placeholder: "YY"
        },
        postalCode: {
            selector: "#postal-code",
            placeholder: "******"
        }
    }

    let submitBtn = document.getElementById('paySubmit');
    braintree.client.create({ authorization: window.site.clientToken }).then(function (client) {
        return braintree.hostedFields.create({
            client: client,
            styles: stylesConfig,
            fields: fieldsConfig
        });
    }).then(function (hostedFields) {
        if (hostedFields.tokenize()) {
            submitBtn.addEventListener('click', function (event) {
                event.preventDefault();
                hostedFields.tokenize().then(function (payload) {
                    // send payload.nonce to your server

                    const err = self.validateDonation();
                    console.log("errvalid",err)
                    if (!err) {
                        self.setState({ showLoader: true});
                        let assettype = 'event'
                        let eventtype = "event";
                        let assetId = localStorage.getItem("assetId") || window.localStorage.getItem("assetId");
                        let channelCode = localStorage.getItem("channelCode");
                        assettype = 'event'
                        if (assettype === "asset") {
                            localStorage.removeItem("channelCode");
                        } else {
                            localStorage.removeItem("a");
                            localStorage.removeItem("assetId") || window.localStorage.removeItem("assetId");
                        }
                        var array = [];
                        var obj;
                        if (assettype === "asset") {
                            obj = { "assetid": assetId }
                        } else if (eventtype === "event") {
                            obj = { "eventid": self.props.eventInfo.event_id }
                        } else {
                            obj = { "channelCode": channelCode }
                        }
                        array.push(obj)
                        
                        let userAssetType;
                        if (assettype === "asset") {
                            userAssetType = `asset`;
                        } else if (eventtype === "event") {
                            userAssetType = `event`;
                        } else {
                             userAssetType = `channel`;
                        }

                        let dataparams = `&payment_method_nonce=${payload.nonce}&token=${token}&cardholderName=${self.state.cardholderName}&price=${self.state.price}&itemType=channel&transactionType=donation&userassetType=${userAssetType}`;
                        axios.post(lambdaUrl + '/transaction?appname=' + appname + '&env=' + environmentVariable + '&' + dataparams, array, { type: 'application/json' })
                            .then((response) => {
                                setTimeout(function () {
                                    if (response && response.data && response.data.statusCode === 200) {

                                        
                                            window.app.storage.setItem('donated',self.state.price);
                                            self.setState({ successmessage: true, showLoader: false, showDonationPayment:false});
                                            // browserHistory.push("/adddevice");
                                    } else {
                                        self.setState({  showLoader: false });
                                        $("#error").html("Credit Card Validation error")
                                    }

                                }, 3000);
                            })
                            .catch((err) => {
                                console.error.bind(err);
                            })


                    }

                }).catch(function (err) {
                    console.log("err", err)
                    $("#error").html(err.message)
                });
            });
        }
    }).catch(function (err) {
        console.log({ err })
        $("#error").html(err.message)
    });

}
successClick() {
  this.setState({ successmessage: false })
}
  onLikeClick(e, asset) {
    e.preventDefault();
    e.stopPropagation();
    if (asset.rating === "approve")
      asset["rating"] = "none"
    else
      asset["rating"] = "approve"
    this.setState({ item: asset })
    let approvebody = {
      "userassetType": 'event',
      "assetid": asset.event_id,
      "activity": "rating",
      "rating": asset["rating"]
    }
    this.props.approvedAsset(approvebody);
  }
  onDisLikeClick(e, asset) {
    e.preventDefault();
    e.stopPropagation();
    if (asset.rating === "disapprove")
      asset["rating"] = "none"
    else
      asset["rating"] = "disapprove"
    this.setState({ item: asset })
    let approvebody = {
      "userassetType": 'event',
      "assetid": asset.event_id,
      "activity": "rating",
      "rating": asset["rating"]
    }
    this.props.approvedAsset(approvebody);
  }
  componentDidUpdate(prevProps) {
    let self = this;
    let newApprovedProps = self.props.approvedProps;
    if (prevProps.item !== self.props.item) {
      self.setState({item: self.props.item});
    }
    if (prevProps.approvedProps && prevProps.approvedProps !== newApprovedProps) {
      if (newApprovedProps && newApprovedProps.userAsset) {
        let assetData = newApprovedProps;
        self.setState(prevState => ({
            item: {                   // object that we want to update
              ...prevState.item,    // keep all other key-value pairs
              rating: assetData.userAsset.rating,
              approveCount: assetData.approveCount,
              disApproveCount: assetData.disApproveCount
            }
          }))
        }
      }
    if (prevProps.votedProps && prevProps.votedProps !== self.props.votedProps) {
      let { item } = this.props;
      if (self.props.item.hasOwnProperty("userVote")) {
        item['userVote'].push({ sessionId: self.props.votedProps.sessionId, voting: self.props.votedProps.key })
      } else {
        item['userVote'] = [{ sessionId: self.props.votedProps.sessionId, voting: self.props.votedProps.key }];
      }
      self.setState({ item: item });
    }
    if (prevProps.allClips !== self.props.allClips) {
       if(!self.props.allClips.error){
        if(self.props.allClips.length > 0){
        self.setState({allClips:self.props.allClips[0].clip ? self.props.allClips[0].clip : []},() =>{
          let subClips = [];
          self.state.allClips.length > 0 && this.state.allClips.forEach(
            (clip,i) => {
              let clipSrc = window.site.config.proxiesCloudFront+'/'+clip.path;
              if(clipSrc){
                 clip['clipSrc'] = clipSrc;
                subClips.push(clip);
                if(this.state.allClips.length - 1 === i){
                self.setState({subClips}, () => {
                  self.setState({tabIndex:1}, () => {self.setupSubclips();});
                })
               }
              }
            });
          
        });
      }
      }
   
    
    }
    if (prevProps.createdClip !== self.props.createdClip) {
      self.setState({creatingClip:false});
      if(!self.props.createdClip.error){
        self.props.getClips(this.props.eventInfo.event_id);
      }
    }
    if (prevProps.eventInfo !== self.props.eventInfo) {
      if(this.props.eventInfo){
      this.props.getClips(this.props.eventInfo.event_id);
      }
    }
    if (prevProps.currentTime !== self.props.currentTime) {
        if (self.props.item && self.props.item.voting && self.props.item.voting.sessions && self.props.item.voting.sessions.length > 0 && !self.props.item.voting.isLive) {
          self.updateSessionVoting()
        }
        if (self.props.item && self.props.item.donation && self.props.item.donation.sessions && self.props.item.donation.sessions.length > 0 && !self.props.item.donation.isLive) {
          self.updateSessionDonation()
        }
    }
  }
  updateSessionVoting(){
    let self = this;
    var result = self.props.item.voting.sessions.find(element => {
      return element.startAt <= self.props.currentTime && self.props.currentTime <= element.endAt
    });
    if(!_.isEqual(result, self.state.activeVoting)){
      if(!result){
        self.setState({activeTab:1});
      }
      self.setState({activeVoting: result});
    }
  }
  updateSessionDonation(){
    let self = this;
    var result = self.props.item.donation.sessions.find(element => {
      return element.startAt <= self.props.currentTime && self.props.currentTime <= element.endAt
    });
    if(!_.isEqual(result, self.state.activeDonation)){
      if(!result){
        self.setState({activeTab:1});
      }
      self.setState({activeDonation: result});
    }
  }
  changeTab(e, tab){
    let self = this;
    e.preventDefault();
    e.stopPropagation();
    self.setState({activeTab:tab, showDonationPayment:false},() =>{
      if(tab === 4 && this.state.tabIndex === 1){
        self.setupSubclips();
      }
    });
  }
  openModal(e) {
    e.preventDefault();
    e.stopPropagation();
    let self = this;
    self.setState({ shareClickPopup: true });
  }

  closeShareModal = (e) => {
    e.stopPropagation();
    e.preventDefault();
    let self = this;
    self.setState({ shareClickPopup: false });
  }
  async createClip(e){
    let self = this;
    if(!self.validate()){
      self.setState({creatingClip:true});
      self.clearClipInfo();
      e.preventDefault();
      e.stopPropagation();
      let playbackUrl = window.videoStreamURL;
      let params = '&eventId=' + this.props.eventInfo.event_id + '&sourceUrl='+playbackUrl+'&startTime='+self.state.startTime+'&endTime='+self.state.endTime+'&clipName='+self.state.clipName
      let body = {startTime: self.state.startTime, endTime: self.state.endTime}
      let serviceProvider = this.props.eventInfo && this.props.eventInfo.input && this.props.eventInfo.input.serviceprovider && this.props.eventInfo.input.serviceprovider;
      self.props.createClip(params, body, serviceProvider)
  }
  }
setupSubclips(){

let self = this;
  let bitmovinPlayerConfig = window.site.config.player.bitmovin;
  let bitmovinPlayerKey = bitmovinPlayerConfig.key || config.DEFAULT_BITMOVIN_PLAYER_KEY;
  let bitmovinAnalyticsKey = config.DEFAULT_BITMOVIN_ANALYTICS_KEY;

  const bitmovin = window.bitmovin;
  this.state.subClips.forEach(
    (clip,i) => {
      let container = document.getElementById('video-player' + (i + 1));
      if (!container) {
        console.warn('Chat.js setupSubclips - no container found');
        return;
      }else if(clip.status === "ERROR"){
        container.innerHTML = '<div class="videoError"><span class="material-icons">  do_disturb_alt   </span>Clip not available to play</div>';
        return;
      }
      else if(clip.status === "Pending") {
        console.warn('Chat.js setupSubclips - no container found');
        container.innerHTML = `<div class="videoError"> <a id="clipsReload"><span class="material-icons">  refresh </span></a> Cliping is in progress</div>`;
        $(function() { $('#clipsReload').on('click', function(){
          self.props.getClips(self.props.eventInfo.event_id);
        });})
        return;
      }
      
      console.debug('Chat.js setupSubclips - in container');

      let playerConfig = {
        key: bitmovinPlayerKey,
        analytics: { },
        playback: {
          autoplay: false,
          muted: true
        },
      };

      if (bitmovinAnalyticsKey) {
        playerConfig.analytics.key = bitmovinAnalyticsKey
      }

      let player = new bitmovin.player.Player(container, playerConfig);
      let videoStreamType = clip.clipSrc.endsWith('.m3u8')
        ? 'hls'
        : 'progressive';
      let playerSource = {
        [videoStreamType]: clip.clipSrc,
      };
      player.load(playerSource).then(() => {
        console.log('Successfully loaded source');
      }, () => {
        console.log('Error while loading source');
      });

    }
  );
  
}
setTabIndex(index){
  let self = this;
  self.setState({tabIndex:index},() => { if(index === 1){
    self.setupSubclips();
  }
  }); 
  
}
feedChange(e){
  window.videoStreamURL = this.props.eventInfo.playerUrls[e.target.value][0].playback.hlsUrl;
  this.setState({selectedFeed:e.target.value});
}
  pickTime() {
    if (window.player) {
      console.log('window.player.getCurrentTime()', window.player.getCurrentTime());
      const secs = window.player.getCurrentTime();
      const formatted = moment.utc(secs * 1000).format('HH:mm:ss:SS');
      console.log('start tomhere pickTime', moment().format());
      let serviceProvider = this.props.eventInfo && this.props.eventInfo.input && this.props.eventInfo.input.serviceprovider && this.props.eventInfo.input.serviceprovider;
      if(serviceProvider == "AWS Media Package"){
        if (this.state.startTime) {
          this.setState({ endTime: moment().format() });
          var startTime = moment(this.state.startTime, 'HH:mm:ss.SS');
          var endTime = moment(moment().format(), 'HH:mm:ss.SS a');
          console.log('start time endTime',startTime, endTime);
          var dur = moment.duration(endTime.diff(startTime));
          dur = moment.utc(dur._milliseconds).format('HH:mm:ss:SS')
          this.setState({ duration: dur });
        } else {
          this.setState({ startTime: moment().format() });
        }
      }else{
      if (this.state.startTime) {
        this.setState({ endTime: formatted });
        var startTime = moment(this.state.startTime, 'HH:mm:ss.SS');
        var endTime = moment(formatted, 'HH:mm:ss.SS a');
        var dur = moment.duration(endTime.diff(startTime));
        dur = moment.utc(dur._milliseconds).format('HH:mm:ss:SS')
        this.setState({ duration: dur });
      } else {
        this.setState({ startTime: formatted });
      }
      }
    }
  }

formatTime(e) {
  let timeInput = e.target.value;
  let intValidNum = timeInput;
  var regex = /^[0-9:]*$/; 
  if( !regex.test(timeInput) ) {
    return false;
}
  let errors = Object.assign({}, this.state.errors);
    delete errors[e.target.name];
  if ((intValidNum.length === 2) || (intValidNum.length === 5 && intValidNum.slice(-2) < 61) || (intValidNum.length === 8 && intValidNum.slice(-2) < 61)) {
    timeInput = timeInput + ":";
    this.setState({[e.target.name]: timeInput});
  }
  if((intValidNum.length === 5 || intValidNum.length === 8 || intValidNum.length === 11) && intValidNum.slice(-2) > 60 ){
   return false
  }
  else if(intValidNum.length < 12){
    this.setState({[e.target.name]: timeInput});
  }
  if(e.target.name === 'endTime'){
    var startTime = moment(this.state.startTime, 'HH:mm:ss.SS a');
    var endTime = moment(timeInput , 'HH:mm:ss.SS a');
    var dur = moment.duration(endTime.diff(startTime));
    dur = moment.utc(dur._milliseconds).format('HH:mm:ss:SS')
    this.setState({duration:dur});
  }else if(e.target.name === 'startTime'){
     startTime = moment(timeInput, 'HH:mm:ss.SS a');
     endTime = moment(this.state.endTime , 'HH:mm:ss.SS a');
     dur = moment.duration(endTime.diff(startTime));
    dur = moment.utc(dur._milliseconds).format('HH:mm:ss:SS')
    this.setState({duration:dur});
  }
  if(e.target.name === 'duration'){
     startTime = moment(this.state.startTime, 'HH:mm:ss.SS a');
     endTime = moment(this.state.endTime , 'HH:mm:ss.SS a');
    timeInput = timeInput.slice(0, timeInput.length - 3);
    timeInput = moment.duration(timeInput).asMinutes();
    endTime = moment(startTime._i, 'HH:mm:ss.SS').add(timeInput, 'minutes').format('HH:mm:ss.SS');
    this.setState({endTime:endTime});
  }
  this.setState({
    errors
});
}
onClipChange(e){
  let errors = Object.assign({}, this.state.errors);
    delete errors[e.target.name];
    this.setState({
        [e.target.name]: e.target.value,
        errors
    });
}
validateDonation() {
  let isError = false;
  let errors = {};

  if (this.state.cardholderName === undefined || this.state.cardholderName === '') {
      isError = true;
      errors.cardholderName = "Enter Cardholder Name";
  }
  this.setState({ errors });

  return isError;
}
validate(){
  let isError = false;
  let errors = {};
  if (this.state.clipName === '') {
    isError = true;
    errors.clipName = "Enter clip name";
}
if (this.state.startTime === '') {
  isError = true;
  errors.startTime = "Enter start time";
}
if (this.state.endTime === '') {
  isError = true;
  errors.endTime = "Enter end time";
}
if(!this.props.eventInfo.playerUrls[0].duration && window.player){
  this.props.eventInfo.playerUrls[0].duration = window.player.getDuration();
}
if (this.state.startTime && this.state.endTime && this.props.eventInfo.playerUrls[0].duration) {
  let startTime = this.state.startTime.slice(0, this.state.startTime.length - 3);
  startTime = moment.duration(startTime).asSeconds();
  let endTime = this.state.endTime.slice(0, this.state.endTime.length - 3);
  endTime = moment.duration(endTime).asSeconds();
  if(startTime > this.props.eventInfo.playerUrls[0].duration){
    isError = true;
    errors.startTime = "Start time should not be greater than total duration";
  }
  if(startTime > endTime){
    isError = true;
    errors.startTime = "Start time should start before end time";
  }
  if(endTime > this.props.eventInfo.playerUrls[0].duration){
    isError = true;
    errors.endTime = "End time should not be after total duration";
  }
}
  this.setState({ errors });
  return isError;
}
clearClipInfo(){
  this.setState({ clipName:'', startTime:'', endTime:'', duration:''});
}

votingClick = (e, selectedItem, vote) => {
  let self = this;
  e.stopPropagation();
  e.preventDefault();
  console.log('hereId', vote, selectedItem);
  let actionbody = {
      "userassetType": 'event',
      "assetid": selectedItem.event_id,
      "activity": "voting",
      "key": "voting-" + vote,
      "sessionId": self.state.activeVoting.id,
      "playerTime": self.props.currentTime ? self.props.currentTime : '',
  }
  console.log('actionbody',actionbody);
  this.props.giveVote(actionbody);
}
donationClick = (e, selectedItem, price) => {
  console.log('here donted',selectedItem, price);

}

selectedSession(selectObject) {
  this.setState({ selectedSession: this.state.item.voting.sessions[selectObject.target.value - 1], selectedSessionNo: selectObject.target.value});
}
showVotingResults(){
  let self = this;
  this.setState({ showVotingResults: !self.state.showVotingResults});
}

handleTextChange(e) {
  let self = this;
  if (!!this.state.errors[e.target.name]) {
      let errors = Object.assign({}, this.state.errors);
      delete errors[e.target.name];
      customer_address[e.target.name] = e.target.value;
      this.setState({
          [e.target.name]: e.target.value,
          errors,
          customer_address
      });
  } else {
      customer_address[e.target.name] = e.target.value;
      self.setState({
          [e.target.name]: e.target.value,
          customer_address
      });
  }
}
  render() {
    let self = this;
    const {showLoader, successmessage, item, message, isOpen, priceMethod, otherPrice, activeTab, currentlyWatchingCount, currentlyWatching, subClips, tabIndex, creatingClip, activeVoting, activeDonation, selectedSession, selectedSessionNo, showVotingResults, showDonationPayment} = this.state;
    // const popup = showPopup ? 'show' : '';
    let posterUrl = item && item.portrait ? item.portrait : item.images && item.images.length > 0 && item.images[0] + window.site.config.imageSuffix;
    let websiteLink = item && item.donation && item.donation.website;
    let classLikeButton = item && item.rating === "approve" ? "flex_div active" : "flex_div";
    let classDisLikeButton = item && item.rating === "disapprove" ? "flex_div active" : "flex_div";
    let info = this.props.eventInfo && this.props.eventInfo.playerUrls && this.props.eventInfo.playerUrls.length > 0 && this.props.eventInfo.playerUrls[0];
    if(info && info.duration){
      var formatted = moment.utc(info.duration*1000).format('HH:mm:ss');
    }

 let isVoted = activeVoting && item.userVote && item.userVote.filter(entry => entry.sessionId === activeVoting.id);
    
    let formattedStartTime = selectedSession && selectedSession.startAt && moment.utc(selectedSession.startAt * 1000).format('HH:mm:ss');
    let formattedEndTime = selectedSession && selectedSession.endAt && moment.utc(selectedSession.endAt * 1000).format('HH:mm:ss');
    let formattedDuration = selectedSession && selectedSession.duration && moment.utc(selectedSession.duration * 1000).format('HH:mm:ss');
    return (
      <div className="chat full-height pos-relative">
        {/* <div className="chat__room-link full-width">
          <button className="room-link popup" onClick={this.handleRoomClick}>
            <span className="room-link__label">{this.props.title}</span>
            <svg className="room-link__svg" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M3.25033 9.99999C3.25033 8.57499 4.40866 7.41666 5.83366 7.41666H9.16699V5.83333H5.83366C3.53366 5.83333 1.66699 7.69999 1.66699 9.99999C1.66699 12.3 3.53366 14.1667 5.83366 14.1667H9.16699V12.5833H5.83366C4.40866 12.5833 3.25033 11.425 3.25033 9.99999ZM6.66699 10.8333H13.3337V9.16666H6.66699V10.8333ZM14.167 5.83333H10.8337V7.41666H14.167C15.592 7.41666 16.7503 8.57499 16.7503 9.99999C16.7503 11.425 15.592 12.5833 14.167 12.5833H10.8337V14.1667H14.167C16.467 14.1667 18.3337 12.3 18.3337 9.99999C18.3337 7.69999 16.467 5.83333 14.167 5.83333Z" fill="white" /></svg>
            <span className={`popuptext ${popup}`} id="myPopup">Watch Party Invite link Copied.</span>
          </button>
          <button className="room-link popup" onClick={this.donation}>
            <a href="javascript:void(0)" ><i className="material-icons mr-0">monetization_on</i></a>

          </button>
        </div>
        <div className="chat__wrapper full-width pos-relative">
          <div className="messages pd-x-1 pos-absolute">
            {this.renderMessages()}
            <div ref={this.messagesEndRef} />
          </div>
        </div> */}

        <div className="chat_header">

          <div className="chat_actions">
          {item && item.eventRating && 
          <div className = "approve_btns">
            <div className="chat_button">
              <button className={classLikeButton} onClick={e => self.onLikeClick(e, item)}><i className="material-icons ">thumb_up</i></button>
                 {self.state.item.approveCount && self.state.item.approveCount}
            </div>
           <div className="chat_button">
              <button className={classDisLikeButton} onClick={e => self.onDisLikeClick(e, item)}><i className="material-icons ">thumb_down</i></button>
              {self.state.item.disApproveCount && self.state.item.disApproveCount}
            </div></div>}
          <div className="chat_button" onClick={e => self.changeTab(e, 2)}>
              <button className={activeTab === 2 ? 'flex_div active' : 'flex_div'} ><i className="material-icons ">forum</i></button>
            Chat
            </div>
            <div className="chat_button creating_clips">
              <button onClick={e => self.changeTab(e, 4)} className={activeTab === 4 ? 'flex_div active' : 'flex_div'} ><i className="material-icons ">content_cut</i></button>
            Create Clips
            </div>
            <div className="chat_button activity_tab" onClick={e => self.changeTab(e, 3)}>
              <button className={activeTab === 3 ? 'flex_div active' : 'flex_div'} ><i className="material-icons ">list_alt</i></button>
            Activity
            </div>
            <div className="chat_button" onClick={e => self.openModal(e)}>
              <button className={'flex_div'} ><i className="material-icons ">share</i></button>
            Share
            </div>
            {item && item.voting && item.voting.allow && activeVoting && <div className="chat_button" onClick={e => self.changeTab(e, 5)}>
              <button className={activeTab === 5 ? 'flex_div active' : 'flex_div'} ><i className="material-icons ">ballot</i></button>
            Vote
            </div>}
            {item && item.donation && item.donation.allow && activeDonation &&  <div className="chat_button" onClick={e => self.changeTab(e, 6)}>
              <button className={activeTab === 6 ? 'flex_div active' : 'flex_div'} ><i className="material-icons ">attach_money</i></button>
            Donate
            </div>}

            {/* <button className="room-link popup room-url" onClick={this.handleRoomClick}>
            <p className="room-link__label">{this.props.title}</p>
            <svg className="room-link__svg" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M3.25033 9.99999C3.25033 8.57499 4.40866 7.41666 5.83366 7.41666H9.16699V5.83333H5.83366C3.53366 5.83333 1.66699 7.69999 1.66699 9.99999C1.66699 12.3 3.53366 14.1667 5.83366 14.1667H9.16699V12.5833H5.83366C4.40866 12.5833 3.25033 11.425 3.25033 9.99999ZM6.66699 10.8333H13.3337V9.16666H6.66699V10.8333ZM14.167 5.83333H10.8337V7.41666H14.167C15.592 7.41666 16.7503 8.57499 16.7503 9.99999C16.7503 11.425 15.592 12.5833 14.167 12.5833H10.8337V14.1667H14.167C16.467 14.1667 18.3337 12.3 18.3337 9.99999C18.3337 7.69999 16.467 5.83333 14.167 5.83333Z" fill="white" /></svg>
            <span className={`popuptext ${popup}`} id="myPopup">Watch Party Invite link Copied.</span>
          </button> */}

            
          </div>
        </div>
        {/* <Tabs className="chatpropstabs">
          <TabList>
            <Tab>Chat</Tab>
            <Tab>People</Tab>
          </TabList>
          <TabPanel>
            <p>
              <b>Chat</b>
            </p>
          </TabPanel>
          <TabPanel>
            <p>
              <b>People</b>
            </p>
          </TabPanel>
        </Tabs> */}
        <div className="chat__wrapper full-width pos-relative">
          <div className="messages pos-absolute">
           {activeTab === 1 &&<div className="chat_info">
              <h2>{item && item.title}</h2>
              <p className="sub_hed"><span>{info && info.releasedate && info.releasedate +' |'} </span> <span>{formatted && formatted +' |'}</span> </p>
              <p>{info && info.description}</p>
            </div>}
            {activeTab === 3 &&
            <div className="activity">
              <div className="activity_hed">
                <div className="hedText">
                  <h3>FEED ACTIVITY</h3>
                  <p className="flex_div"><span className="material-icons">visibility</span> {currentlyWatchingCount} Currently watching</p>
                </div>

                <button onClick={e => self.changeTab(e, 1)} className="wp_close"><span className="material-icons">  close</span></button>

              </div>
              <div className="activity_content">
                <ul>
                 {Object.entries(currentlyWatching).map(([key, val], i) => {
                   let name = val.name.substring(0, 1);
                   return (<li key={i}><div className="name_thumb">{name}</div>
                    <p><span> {val.name} joined the party </span></p>
                   </li>)})}
                </ul>
              </div>
            </div>
            }
            {activeTab === 2 && this.renderMessages()}
            {activeTab === 5 &&
            <div className="activity">
              <div className="activity_hed">
                <div className="hedText">
                  <h3>Voting</h3>
                </div>
                <button onClick={e => self.changeTab(e, 1)} className="wp_close"><span className="material-icons">  close</span></button>
              </div>
              <div className="voting_content">
                <div className="voting_block">
                  <div id="votings">
                  
                    {activeVoting && activeVoting.votes.map((vote, i) => {
                    return (
                        <button key={i} disabled={isVoted && isVoted.length > 0} className="button" onClick={e => self.votingClick(e, item, vote)}><span className={isVoted && isVoted[0] && isVoted[0].voting === 'voting-' + vote ? "active material-icons font20":"material-icons font20"}>check_circle</span>{vote}</button>)
                    })}
                    
                  </div>
                </div>
                {selectedSession && selectedSession.votesRegistered && this.state.item.isJudge &&<div><button className="poll_btn" onClick={e => self.showVotingResults()}>
                  <span className="material-icons">
                    leaderboard
                            </span>Get Vote Results</button>
                  {showVotingResults && <div className="rt_select" id="rating_block">
                    <select onChange={e => self.selectedSession(e)}>
                      {this.state.item && this.state.item.voting && this.state.item.voting.sessions && this.state.item.voting.sessions.map((vote, i) => {
                        return (<option selected={i === 0 ? 'selected' : ''} value={i + 1}>Session {i + 1}</option>)
                      })}
                    </select>
                    <div><h3>Session No {selectedSessionNo}</h3><div className="rating"><div className="rt_hed"><p>{formattedStartTime}-{formattedEndTime}</p><p><b>{formattedDuration}</b></p></div> <ul className="rt_list" >
                      {selectedSession && selectedSession.votesRegistered && Object.keys(selectedSession.votesRegistered).map((keyName, i) => {
                        return (
                          <li><p>{keyName}</p><p><b>{selectedSession.votesRegistered[keyName]}</b></p></li>
                        )
                      })}
                      <li className="total"><p>Total</p><p><b>{selectedSession.totalVotes}</b></p></li>
                    </ul></div></div>
                  </div>}
                </div>}
                       
              </div>
            </div>
            }
            {activeTab === 6 &&
              <div className="activity">
                <div className="activity_hed">
                  <div className="hedText">
                    <h3>Donation</h3>
                  </div>
                  <button onClick={e => self.changeTab(e, 1)} className="wp_close"><span className="material-icons">  close</span></button>
                </div>
                <div className="activity_content">
                  {!showDonationPayment && <div className="donation_block_side">
                    <div id="donationAmounts">
                      {activeDonation && activeDonation.price.map((price, i) => {
                        return (
                          <button key={i} className="button" onClick={e => self.setState({ price,  priceMethod:"price" }, () => self.showDonationPayment())}><span className="dallor font20">$</span>{price}</button>)
                      })}
                      </div>
                      {activeDonation &&  <div className="text_box">
                        <input type="number" name="otherPrice" value={otherPrice} onChange={e => self.onChange(e)} />
                        <button className="addDonBtn" onClick={e => self.showDonationPayment()}><span className="material-icons">
                          chevron_right
                            </span></button>
                      </div>}
                   

                  </div>}
                {showDonationPayment &&
                  <div className="payment_block" >
                    {/* <button className="close" id="paymentcloseButton" onClick={e => self.showDonationPayment()}><span className="material-icons">
                      close
                   </span></button> */}
                    <h4 className="sub-text">ENTER PAYMENT INFO</h4>
                    <div className="pmt_lft1 mob-u-pmt_lft">

                      <div className="row ast_infoo1">
                       <div className="col-md-12 row2">
                         
                          <h5><label>Donation </label><span>:</span><span className="assetPrice">${self.state.price}</span></h5>

                          {/* <h5 className="envoiskuDiv"><label>SKU</label><span>:</span><span className="envoisku"></span></h5> */}
                        </div>
                      </div>

                    </div>
                    <div className="pmt_rgt1">
                      <form id="cardForm_Don" method="post">
                        <div className="pmt_blk1">
                          <p className="ps3">ACCOUNT CREATED FOR: <span className="userEmail">{localStorage.getItem("email")}</span></p>
                          <h3>PAYMENT METHOD</h3>
                          <div className="row pay_opt">
                            <div className="col-md-12">
                              <div className="form-group">
                                <input type="radio" id="creditcard_Don" name="paymenttype" value="creditcard" checked />
                                <img alt="pay" src="https://envoi-common-resources.imgix.net/screening/images/dark/payment-credit-card.png" />
                              </div>

                            </div>
                          </div>
                          <div className="cc_det paymentopt" id="creditcard-container">
                            <div className="cc_det">
                              <div className="row">
                                <div className="col-md-12">
                                  <div className="form-group">
                                    <label>CARDHOLDER NAME</label>
                                    <input type="text" value={this.state.cardholderName} className="hosted-field"
                                      name="cardholderName" onChange={e => this.handleTextChange(e)} />
                                    <span className="errormsg"></span>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-12">
                                <div className="form-group">
                                  <label>CARD NUMBER</label>
                                  <div id="card-number" className="hosted-field"></div>
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-4">
                                <div className="form-group">
                                  <label className="pmt_lbl">EXPIRATION</label>
                                  <div id="expiration-month" className="hosted-field"></div>
                                  <div id="expiration-year" className="hosted-field"></div>
                                </div>
                              </div>

                              <div className="col-md-4">
                                <div className="form-group">
                                  <label>CVV</label>
                                  <div id="cvv" className="hosted-field"></div>
                                </div>
                              </div>

                              <div className="col-md-4">
                                <div className="form-group">
                                  <label>ZIP CODE</label>
                                  <div id="postal-code" className="hosted-field"></div>
                                </div>
                              </div>
                            </div>

                            <div id="error" style={{ color: "#f00", fontSize: '14px'}} >{self.state.errMessage}</div>
                          </div>

                        </div>


                        <p className="braintreeError paymentError mb-2 mt-2"></p>

                        <div className="form-group">
                          <input type="button" id="paySubmit" value="PAY" className="submit" />
                        </div>
                      </form>
                    </div>

                  </div>
                }
                </div>
                
              </div>
            }
            {activeTab === 4 && <div>
             <div className="activity">
                <div className="activity_hed">
                  <div className="hedText">
                    <h3>CLIPS</h3>
                  </div>
                  <a onClick={e => self.changeTab(e, 1)} className="wp_close" href="#!"><span className="material-icons">  close</span></a>
                </div>
                <div className="activity_content">
                  <Tabs className="clips_tabs" onSelect={index => this.setTabIndex(index)} selectedIndex={tabIndex}>
                    <TabList>
                      <Tab>Add</Tab>
                      <Tab>List</Tab>
                    </TabList>
                    <TabPanel>
                      <div className="add_clips">
                        {this.props.eventInfo && this.props.eventInfo.playerUrls && this.props.eventInfo.playerUrls.playlistType && this.props.eventInfo.playerUrls.playlistType === 'Multiplex' && <div><h6>Feed</h6>
                          <select value={this.state.selectedFeed} name="feed" className="" onChange={e => this.feedChange(e)}>
                            <option value={''}>Select feed</option>
                            {Object.keys(this.props.eventInfo.playerUrls).length > 0 &&
                              Object.keys(this.props.eventInfo.playerUrls).map((feed, i) => {
                                return (feed === 'playlistType') ? null : <option key={i} value={feed}>{feed}</option>;
                              })}
                          </select></div>}
                        
                        <h6>Title</h6>
                        <input onChange={e => this.onClipChange(e)} autoComplete="off" value={this.state.clipName} type="text" name="clipName" placeholder="Name"/>
                        <p className="error_red">{this.state.errors.clipName}</p>
                        <h6>Start Time</h6>
                        <div className="startTime">
                        <input type="text" autoComplete="off"  maxLength="12" value={this.state.startTime || ""} onChange={e => this.formatTime(e)} name="startTime" placeholder="00:00:00:00"/>
                        <span onClick={e => self.pickTime(e)} className="material-icons timePickerIcon">
                          schedule</span>
                        </div>
                        <p className="error_red">{this.state.errors.startTime}</p>
                        <h6>End Time</h6>
                        <div className="startTime"><input type="text" autoComplete="off"  maxLength="12" name="endTime" value={this.state.endTime || ""} onChange={e => this.formatTime(e)} placeholder="00:00:00:00"/>
                        <span onClick={e => self.pickTime(e)} className="material-icons timePickerIcon">
                          schedule</span></div>
                        <p className="error_red">{this.state.errors.endTime}</p>
                        <h6>Duration</h6>
                        <input type="text" autoComplete="off"  maxLength="12" name="duration" value={this.state.duration || ""} onChange={e => this.formatTime(e)} placeholder="00:00:00:00"/>
                        
                        <div className="btns_group mt-3">
                        {!creatingClip &&<button disabled={this.props.eventInfo && this.props.eventInfo.playerUrls && this.props.eventInfo.playerUrls.playlistType && this.props.eventInfo.playerUrls.playlistType === 'Multiplex' && !this.state.selectedFeed} onClick={e => self.createClip(e)} className="color_btns mr-3">Save</button>}
                        {creatingClip && <button className="color_btns mr-3">
                            <i className="fa fa-spinner fa-spin"/>Creating
                          </button>}
                          <button className="color_btns" onClick={e => self.clearClipInfo(e)}> Reset</button>
                        </div>
                      </div>
                    </TabPanel>
                    <TabPanel>
                      {subClips && subClips.length > 0 && subClips.map((clip, i) => {

                        return (
                          <div key={i} className="subclipplayer" id={"video-player" + (i + 1)} />
                        )
                      })
                      }
                    </TabPanel>
                  </Tabs>
                </div>
              </div> 
              </div>}
            <div ref={this.messagesEndRef} />
           
          </div>
        </div>
        {activeTab === 2 && <div className="chime-web-composer">
         <div className="chat_type">
           <div className="name_thumb">{firstname}</div>
           <button onClick={(e) => this.postMessage(e)} className="post_btn">Post</button>
          <input
            ref={this.chatRef}
            className="chat_input"
            type="text"
            placeholder="Add a comment"
            value={message}
            maxLength={510}
            onChange={this.handleChange}
            onKeyDown={this.handleKeyDown}
          />
          </div>
        </div>}
        <Modal
                    isOpen={successmessage}
                    style={customStyles}
                    contentLabel="Example Modal"
                    className="custom_modal u-conf-popup">
                    <div className="active_wrp">
                        <div className="active_block">
                            <p className="wte">Thank you for your support.</p>
                            <div className="form-group">
                                <button type="button" className="submit cncl" onClick={e => self.successClick()}>Ok</button>
                            </div>
                        </div>
                    </div>
                </Modal>
        <Modal
          isOpen={isOpen}
          style={customStyles}
          contentLabel="Example Modal"
          className="donate_popup">
          <div className="modal-head ">
            <h2>DONATE</h2>
            <a href="#!" onClick={e => self.closeModal(e)} className="dismiss_btn">
              <i className="material-icons">close</i>
            </a>
            <div className="donate-header">
              <div className="profile_block">
                <img alt="poster" src={posterUrl} />
              </div>
              <div className="header-desc">
                <h5>{item && item.donation && item.donation.title}</h5>
                {/* <p>{item && item.donation && item.donation.description}</p> */}
              </div>
            </div>
          </div>
          <div className="modal-body">
            <p className="don_by">Donation collected by
                        {websiteLink ?
                <a href={websiteLink} target="_blank" rel="noreferrer">Website <i className="material-icons">open_in_new</i></a> : null}
            </p>
            <div className="don_block">
              <div className="don_list">
                {item && item.donation && item.donation.price && item.donation.price.length > 0 && item.donation.price.map(function (price, i) {
                  return (
                    <label key={i} className="radio_block">
                      <input type="radio" value={price} name="radio" onClick={e => self.onChangePrice(price, "price")} />
                      <span className="title">${price}</span>
                      <span className="checkblock"/>
                    </label>
                  )
                })
                }
                <label className="radio_block">
                  <input type="radio" name="radio" value={self.state.price} onClick={e => self.onChangePrice(self.state.price, "other")} />
                  <span className="title">Other</span>
                  <span className="checkblock"/>
                </label>
              </div>
              {priceMethod === "other" ?
                <div className="other_amnt">
                  <label>Other Amount</label>
                  <div className="enter_amnt">
                    <p>$</p>
                    <input type="text" name="otherPrice" value={otherPrice} onChange={e => self.onChange(e)} />
                  </div>
                </div> : null}
            </div>
            <div className="condition mt-4">
              <input type="checkbox" />
              <p>Show my name along with my donation amount in live chat Even if you don’t select this option, your donation amount (but no your name) may still be displayed during more popular live streams</p>
            </div>
          </div>
          <div className="modal-footer nobord nopad_tp">
            <button className="btn cont-btn canc-btn" onClick={e => self.closeModal(e)}>CANCEL</button>
            <button className={`btn cont-btn${self.state.price ? '' : 'disabled'}`} onClick={e => self.continue()}>CONTINUE</button>
          </div>
        </Modal>
        {showLoader && <Loader />}
        <AssetInfoShare  item={this.props.item}
     title={this.props.title} closeModal={this.closeShareModal} shareClickPopup={this.state.shareClickPopup}  />
      </div>
    )
  }
}

Chat.propTypes = {
  chime: PropTypes.object,
  title: PropTypes.string,
  username: PropTypes.string,
  joinInfo: PropTypes.object,
  eventInfo: PropTypes.object,
  item: PropTypes.object,
};
const mapState = ({ approvedProps, allClips, createdClip, votedProps}) => ({ approvedProps, allClips, createdClip, votedProps});
const mapDispatch = { approvedAsset, getClips, createClip, giveVote};
export default withRouter(connect(mapState, mapDispatch)(Chat));
// export default Chat;
