import axios from "axios";
import {
    ADD_PROFILE,
    APPROVED_ASSET,
    ASSET_PAYMENT,
    BRAINTREE_CUSTOMER,
    GENERATE_ACTIVATION_CODE,
    GENERATE_USER_RELATION,
    GET_ADDRESS,
    GET_ALL_PARTNERS,
    GET_ASSET_INFO,
    GET_COUNTRIES,
    GET_EVENT_INFO,
    GET_EVENTS,
    GET_REGIONS,
    GET_USER,
    GET_WEBSITE_DEFAULT_DATA,
    ITEMS_IS_LOADING,
    GET_CLIPS,
    CREATE_CLIPS,
    GIVE_VOTE
} from './ActionTypes'

import {
    activationCodeProps,
    approvedProps,
    assetInfo,
    assetPayment,
    braintreeCustomer,
    dispatch_addprofile,
    dispatch_address,
    dispatch_countries,
    dispatch_events,
    eventInfo,
    generateActivationCodeProps,
    generateUserRelationProps,
    getPartners,
    itemsIsLoading,
    regions,
    userInfo,
    websiteDefaults,
    allClips,
    createdClip,
    votedProps,
    dispatch_location,
} from './Actions'
import $ from 'jquery';
import moment from 'moment';
//initiate your starting state
let initial = {
    approvedProps: [],
    assetInfo: [],
    assetPayment: [],
    braintreeCustomer: [],
    eventInfo: [],
    events: [],
    generateActivationCodeProps: [],
    getPartners: [],
    loading: false,
    regions: [],
    userinfo: [],
    userRelationData: {},
    websiteDefaultData: [],
    allClips:[],
    createdClip:[],
    votedProps: []
};



let appSettings = window.app
let siteSettings = window.site;

console.log('reducer.js', { appSettings, siteSettings });

let siteConfig = (siteSettings && siteSettings.config) || (appSettings && appSettings.defaultSiteSettings && appSettings.defaultSiteSettings.config);
let envoiScreeningApiBaseUrl = siteSettings && siteSettings.screeningApiBaseUrl;
let commonApi = siteConfig.commonApi;
let appStorage = appSettings.storage;

let token = appStorage.getItem('token');

window.paymentEnable = siteConfig.subscription.enable
window.requiredSelectDevice = siteConfig.requiredSelectDevice;
window.requiredVerification = siteConfig.requiredVerification;
let assetsCount = 12;
let pageNumber = 1;
let scrubbing = true;

/////////////////REDUCER/////////////////////
const reducer = (state = initial, action) => {
    switch (action.type) {
        case ADD_PROFILE:
            return Object.assign({}, state, { addingProfile: action.addProfile });
        case APPROVED_ASSET:
            return Object.assign({}, state, { approvedProps: action.approvedState })
        case ASSET_PAYMENT:
            return Object.assign({}, state, { assetPayment: action.asset });
        case BRAINTREE_CUSTOMER:
            return Object.assign({}, state, { braintreeCustomer: action.braintreeCustomerState });
        case GENERATE_ACTIVATION_CODE:
            return Object.assign({}, state, { generateActivationCodeProps: action.generateActivationCodeState });
        case GENERATE_USER_RELATION:
            return Object.assign({}, state, { userRelationData: action.userRelationData });
        case GET_ADDRESS:
            return Object.assign({}, state, { address: action.address });
        case GET_ALL_PARTNERS:
            return Object.assign({}, state, { getPartners: action.partners });
        case GET_ASSET_INFO:
            return Object.assign({}, state, { assetInfo: action.asset });
        case GET_COUNTRIES:
            return Object.assign({}, state, { countries: action.countries });
        case GET_EVENT_INFO:
            return Object.assign({}, state, { eventInfo: action.event });
        case GET_EVENTS:
            return Object.assign({}, state, { events: action.events });
        case GET_REGIONS:
            return Object.assign({}, state, { regions: action.regionsState });
        case GET_USER:
            return Object.assign({}, state, { userInfo: action.user });
        case GET_WEBSITE_DEFAULT_DATA:
            return Object.assign({}, state, { websiteDefaultData: action.websiteDefaultData });
        case ITEMS_IS_LOADING:
            return Object.assign({}, state, { loading: action.loading });
        case GET_CLIPS:
            return Object.assign({}, state, { allClips: action.clips });
        case CREATE_CLIPS:
            return Object.assign({}, state, { createdClip: action.clips });
        case GIVE_VOTE:
            return Object.assign({}, state, { votedProps: action.votedState });
        default:
            return state;
    }
};

export default reducer;

// Price Format
export const priceFormat = (value, currency, countryCode) => dispatch => {
    const formatter = new Intl.NumberFormat(countryCode, {
        style: 'currency',
        currency: currency,
    });

    return formatter.format(value);
}

/////////////// ACTION GET WEBSITE_DEFAULT_DATA///////////////////
export const getWebsiteDefault = () => dispatch => {
    dispatch(itemsIsLoading(true));
    window.getAndProcessSiteSettings()
    .then((siteSettings) => {
        dispatch(itemsIsLoading(false));
        dispatch(websiteDefaults(siteSettings));
        return siteSettings;
    })
    .catch((err) => {
        dispatch(websiteDefaults([]))
        console.error.bind("reducer.js getWebsiteDefault", { err });
    })
};

export function activationCode(bodydata) {
    token = appStorage.getItem('token');
    return (dispatch) => {
        let url = envoiScreeningApiBaseUrl + '/activationCode?appname=' + window.app.appName + '&env=' + window.app.appEnvName + '&token=' + token + '&pairingcode=' + bodydata.pairingcode;
        if (bodydata.userid)
            url += "&uid=" + bodydata.userid

        axios.post(url, { type: 'application/json' })
            .then((response) => {
                if (response.data.statusCode === 401) {
                    return response.data;
                } else {
                    return response.data.result;
                }
            })
            .then((activationCodeState) => {
                dispatch(activationCodeProps(activationCodeState))
            })
            .catch((err) => {
                dispatch(activationCodeProps(err.response.data));
                console.error.bind("reducer.js activationCode", { err });
            })
    };
}


export const getArtists = (menu, creatorType) => dispatch => {
    dispatch(itemsIsLoading(true));
    token = appStorage.getItem('token');
    let url = envoiScreeningApiBaseUrl + '/getArtists?appname=' + window.app.appName + '&env=' + window.app.appEnvName + "&token=" + token + "&menu=" + menu + '&assetcount=' + assetsCount + '&pageNumber=' + pageNumber + '&scrubbing=' + scrubbing;

    if (creatorType) {
        url += "&creatorType=" + creatorType;
    }
    axios.get(url)
        .then((response) => {
            dispatch(itemsIsLoading(false));
            return response.data.result;
        })
        .then((partners) => {
            dispatch(getPartners(partners))
        })
        .catch((err) => {
            console.error.bind("reducer.js getArtists", { err });
        })
};

export const getUser = (token) => dispatch => {
    dispatch(itemsIsLoading(true));
    axios.post(envoiScreeningApiBaseUrl + '/getuser?appname=' + window.app.appName + '&env=' + window.app.appEnvName + '&token=' + token)
        .then((response) => {
            dispatch(itemsIsLoading(false));
            return response.data.result;
        })
        .then((user) => {
            dispatch(userInfo(user))
        })
        .catch((err) => {
            console.error.bind("reducer.js getUser", { err });
        })
};

export const customerAddress = (formData, id) => dispatch => {
    dispatch(itemsIsLoading(true));
    token = appStorage.getItem('token');
    axios.post(envoiScreeningApiBaseUrl + '/address?token=' + token + '&env=' + window.app.appEnvName + '&appname=' + window.app.appName + '&id=' + id, formData, { type: 'application/json' })
        .then((response) => {

            dispatch(itemsIsLoading(false));
            return response.data.result;
        })
        .catch((err) => {
            console.error.bind("reducer.js customerAddress", { err });
        })
};

export const updateUserRelation = (formData) => dispatch => {
    
    dispatch(itemsIsLoading(true));
    token = appStorage.getItem('token');
    axios.post(envoiScreeningApiBaseUrl + '/partnerDetails?token=' + token + '&env=' + window.app.appEnvName + '&appname=' + window.app.appName, formData, { type: 'application/json' })
        .then((response) => {
            // if (window.siteConfig.addcardEnable) {
            //     this.props.history.push("/addcard");
            // } else if (paymentEnable == true && creatorpromolink && creatorpromocode) {
            //     window.location = "/Payment?planId=" + creatorpromolink;
            // } else if (paymentEnable == true) {
            //     this.props.history.push("/Payment");
            // } else if (requiredSelectDevice == true) {
            //     this.props.history.push("/adddevice");
            // } else {
            //     this.props.history.push("/activation");
            // }
            dispatch(itemsIsLoading(false));
            return response.data.result;
        })
        .then((UserRelationData) => {
            dispatch(generateUserRelationProps(UserRelationData))
        })
        .catch((err) => {
            console.error.bind("reducer.js updateUserRelation", { err });
        })
};

export function addProfiles(name, profileImage) {
    return (dispatch) => {

        let createProfile = { "profileName": name, "profileImage": profileImage }

        axios.post(envoiScreeningApiBaseUrl + '/createProfile?appname=' + window.app.appName + '&env=' + window.app.appEnvName + '&token=' + token, createProfile, { type: 'application/json' })
            .then((response) => {
                if (response.data.statusCode === 200) {
                    $(document).ready(function () {
                        $('#SuccessAddPopup').css("display", "block");
                        setTimeout(function () { $('#SuccessAddPopup').css('display', 'none'); }, 2000);
                    });
                }
                else if (response.data.statusCode === 401) {
                    $(document).ready(function () {
                        if (response.data.error && response.data.error === 'profileName exists') {
                            $('#ErrorPopup').css("display", "block");
                            setTimeout(function () { $('#ErrorPopup').css('display', 'none'); }, 2000);
                        }

                    });
                }

                return response.data.result;
            })
            .then((addProfile) => {
                dispatch(dispatch_addprofile(addProfile))
            })
            .catch((err) => {
                console.error.bind("reducer.js addProfiles", { err });
            })
    };
}

export function getCountries(token) {
    return (dispatch) => {
        dispatch(itemsIsLoading(true));
        commonApi = (window.site.config || window.app.defaultSiteSettings.config).commonApi;
        axios.get(commonApi + '/countries')
            .then((response) => {
                dispatch(itemsIsLoading(false));
                return response.data.result;
            })
            .then((countries_result) => {
                dispatch(dispatch_countries(countries_result))
            })
            .catch((err) => {
                console.error.bind("reducer.js getCountries", { err });
            })
    };
}

export function getRegionsAPI(alpha3) {
    return (dispatch) => {
        dispatch(itemsIsLoading(true));
        commonApi = (window.site.config || window.app.defaultSiteSettings.config).commonApi;
        axios.get(commonApi + '/regions/' + alpha3)
            .then((response) => {
                dispatch(itemsIsLoading(false));
                return response.data.result;
            })
            .then((regionsState) => {
                dispatch(regions(regionsState))
            })
            .catch((err) => {
                console.error.bind("reducer.js getRegionsAPI", { err });
            })
    };
}

export function getAddress(addresstype) {
    return (dispatch) => {
        dispatch(itemsIsLoading(true));
        token = appStorage.getItem('token');
        axios.post(envoiScreeningApiBaseUrl + '/getaddress?&appname=' + window.app.appName + '&env=' + window.app.appEnvName + '&token=' + token + '&addresstype=' + addresstype)
            .then((response) => {
                dispatch(itemsIsLoading(false));
                return response.data.result;
            })
            .then((address_result) => {
                dispatch(dispatch_address(address_result))
            })
            .catch((err) => {
                console.error.bind("reducer.js getAddress", { err });
            })
    };
}

export const addCustomerInBrainTree = () => dispatch => {
    token = appStorage.getItem('token');
    axios.post(envoiScreeningApiBaseUrl + '/addcard?token=' + token + '&appname=' + window.app.appName + '&env=' + window.app.appEnvName + "&createCustomer=true")
        .then((response) => {
            return response.data.result;
        })
        .then((braintreeCustomerState) => {
            dispatch(braintreeCustomer(braintreeCustomerState))
        })
        .catch((err) => {
            console.error.bind("reducer.js addCustomerInBrainTree", { err });
        })
};

export function generateActivationCode(device) {
    return (dispatch) => {
        dispatch(itemsIsLoading(true));
        token = appStorage.getItem('token');
        axios.post(envoiScreeningApiBaseUrl + '/generateActivationCode?appname=' + window.app.appName + '&env=' + window.app.appEnvName + '&token=' + token + '&device=' + device)
            .then((response) => {
                dispatch(itemsIsLoading(false));
                if (response.data.statusCode === 401)
                    return response.data;
                else
                    return response.data.result;
            })
            .then((generateActivationCodeState) => {
                dispatch(generateActivationCodeProps(generateActivationCodeState))
            })
            .catch((err) => {
                dispatch(generateActivationCodeProps(err.response.data));
                console.error.bind("reducer.js generateActivationCode", { err });
            })
    };
}

export function getEvents(eventid, eventType) {
    return (dispatch) => {
        dispatch(itemsIsLoading(true));
        let token = appStorage.getItem('token');
        let url = envoiScreeningApiBaseUrl + '/getEvents?env=' + window.app.appEnvName + '&token=' + token;
        if (eventid) url += '&id=' + eventid;
        if (eventType) url += '&eventType=' + eventType;
        url += '&appname=' + window.app.appName
        axios.get(url)
            .then((response) => {
                dispatch(itemsIsLoading(false));
                if (response.data.error && response.data.error.message === "Invalid Token") {
                    dispatch(logOut())
                }else{
                return response.data.result;
                }
            })
            .then((events) => {
                dispatch(dispatch_events(events))
            })
            .catch((err) => {
                console.error.bind("reducer.js getEvents", { err });
            })
    };
}

export const approvedAsset = (actionBody) => dispatch => {
    // dispatch(itemsIsLoading(true));

    axios.post(envoiScreeningApiBaseUrl + '/manageUserAssetActivity?appname=' + window.app.appName + '&env=' + window.app.appEnvName + '&token=' + token, actionBody, { type: 'application/json' })
        .then((response) => {
            dispatch(itemsIsLoading(false));
            return response.data.result;
        })
        .then((approvedState) => {

            dispatch(approvedProps(approvedState))
        })
        .catch((err) => {
            console.error.bind("reducer.js approvedAsset", { err });
        })
};

export const giveVote = (actionBody) => dispatch => {
    // dispatch(itemsIsLoading(true));
    axios.post(envoiScreeningApiBaseUrl + '/manageUserAssetActivity?appname=' + window.app.appName + '&env=' + window.app.appEnvName + '&token=' + token, actionBody, { type: 'application/json' })
        .then((response) => {
            dispatch(itemsIsLoading(false));
            return response.data.result;
        })
        .then((votedState) => {

            dispatch(votedProps(votedState))
        })
        .catch((err) => {
            console.error.bind("reducer.js votedProps", { err });
        })
};


export const getPricing = (token, payment_method_nonce, price, arrayAssets, optionalAddress, optionalCity, quality, itemType, transactionType, sku, cardholderName, envoiSku, isSingleEventPurchase) => dispatch => {
    dispatch(itemsIsLoading(true));
    token = appStorage.getItem('token');
    let url = envoiScreeningApiBaseUrl + '/assetPayment?appname=' + window.app.appName + '&env=' + window.app.appEnvName + '&token=' + token + '&payment_method_nonce=' + payment_method_nonce + '&transactionType=' + transactionType + '&itemType=' + itemType + '&price=' + price + '&address=' + optionalAddress + '&city=' + optionalCity + '&quality=' + quality + '&userassetType=' + itemType;
    if (sku) {
        url += "&sku=" + sku;
    }
    if(isSingleEventPurchase){
        url += "&isSingleEventPurchase=" + isSingleEventPurchase;
    }
    if (cardholderName) {
        url += "&cardholderName=" + cardholderName;
    }
    if (envoiSku) {
        url += "&envoisku=" + envoiSku;
    }
    axios.post(url, arrayAssets)
        .then((response) => {
            dispatch(itemsIsLoading(false));
            if (response.data.statusCode === 401) {
                let error = {
                    error: response.data.error?response.data.error:"Call Failed"
                }
                dispatch(assetPayment(error))
            } else {
                return response.data.result;
            }
        })
        .then((asset) => {
            dispatch(assetPayment(asset))
        })
        .catch((err) => {
            let error = {
                error: "Call Failed"
            }
            dispatch(assetPayment(error))
            console.error.bind("reducer.js getPricing", { err });
        })
};

export const getAssetInfo = (assetId, token) => dispatch => {
    token = appStorage.getItem('token');

    let idFieldName =  (assetId && token) ? 'event_id' : 'assetid';
    let requestUrl = `${envoiScreeningApiBaseUrl}/assetinfo?appname=${window.app.appName}&env=${window.app.appEnvName}&${idFieldName}=${assetId}&scrubbing=${scrubbing}`;
    if (assetId && token) {
        requestUrl += '&token=' + token;
    }

    dispatch(itemsIsLoading(true));
    axios.post(requestUrl)
        .then((response) => {
            if (response.data.statusCode === 401) {
                $(document).ready(function () {
                    //  window.location = "/notfound";
                });
            }
            dispatch(itemsIsLoading(false));
            return response.data.result;
        })
        .then((asset) => {

            dispatch(assetInfo(asset))
        })
        .catch((err) => {
            console.error.bind("reducer.js getAssetInfo", { err });
        })
};

export const getEventInfo = (assetid) => dispatch => {
    token = appStorage.getItem('token');
    let requestUrl = envoiScreeningApiBaseUrl + '/eventInfo?eventId=' + assetid + '&token=' + token + '&appname=' + window.app.appName + '&env=' + window.app.appEnvName;
    if (window.app.windowLocation) {
        requestUrl += '&'+window.app.windowLocation.href || ""
    }

    dispatch(itemsIsLoading(true));
    axios.post(requestUrl)
        .then((response) => {
            if (response.data.statusCode === 401) {
                $(document).ready(function () {
                    //  window.location = "/notfound";
                });
            }
            dispatch(itemsIsLoading(false));
            return response.data.result;
        })
        .then((event) => {

            dispatch(eventInfo(event))
        })
        .catch((err) => {
            console.error.bind("reducer.js getEventInfo", { err });
        })
};

export const msToTime = (duration) => dispatch => {
    duration = duration * 1000;
    let seconds = parseInt((duration / 1000) % 60)
        , minutes = parseInt((duration / (1000 * 60)) % 60)
        , hours = parseInt((duration / (1000 * 60 * 60)) % 24);

    hours = (hours < 10) ? "0" + hours : hours;
    minutes = (minutes < 10) ? "0" + minutes : minutes;
    seconds = (seconds < 10) ? "0" + seconds : seconds;

    return hours + ":" + minutes + ":" + seconds;
};

export const logOut = () => dispatch => {
    var myAppName = appStorage.getItem('appName');
    appStorage.clear();
    appStorage.setItem('appName',myAppName);
    window.location = '/';
};

export const getClips = (eventId) => dispatch => {
    dispatch(itemsIsLoading(true));
    token = appStorage.getItem('token');
    let url = 'https://content-screening-api.dev.envoi.cloud/clips?token=' + token + '&env=' + window.app.appEnvName + '&eventId='+eventId+'&appname='+ window.app.appName;

    
    axios.get(url)
        .then((response) => {
            dispatch(itemsIsLoading(false));
            if (response.data.statusCode === 401) {
                return response.data;
            } else {
                return response.data.result;
            }
        })
        .then((clips) => {
            dispatch(allClips(clips))
        })
        .catch((err) => {
            console.error.bind("reducer.js getClips", { err });
        })
};

export const createClip = (params, bodyparams, serviceProvider) => dispatch => {
    dispatch(itemsIsLoading(true));
    token = appStorage.getItem('token');
    let url = envoiScreeningApiBaseUrl + '/createClip?env=' + window.app.appEnvName + '&token=' + token + params +'&appName=' + window.app.appName;
    let body = '';
    if(serviceProvider && serviceProvider === 'AWS Media Package'){
        url = envoiScreeningApiBaseUrl + '/harvestJob?env=' + window.app.appEnvName + '&token=' + token +'&appName=' + window.app.appName;
        console.log('start tomhere', moment().format());
        body = {
            "startTime": bodyparams.startTime,
            "endTime": bodyparams.endTime,
            "originEndpointId": "2C582335_71EF_4BD9_934C_BF7A27877FDF_Envoi_Peafowl_Meadia_Package_Test-vimond"
        }
    }

    console.log('url rajesh', url, body);
    axios.post(url, body, { type: 'application/json' })
        .then((response) => {
            dispatch(itemsIsLoading(false));
            if (response.data.statusCode === 401) {
                return response.data;
            } else {
                return response.data.result;
            }
        })
        .then((clips) => {
            dispatch(createdClip(clips))
        })
        .catch((err) => {
            console.error.bind("reducer.js getClips", { err });
        })
};

export const getLocation = () => dispatch => {
    siteSettings = window.site;
    siteConfig = (siteSettings && siteSettings.config) || (appSettings && appSettings.defaultSiteSettings && appSettings.defaultSiteSettings.config);
    console.log('siteConfig.geoLocatoinCloudfrontUrl',siteConfig.geoLocatoinCloudfrontUrl);
    axios.get(siteConfig.geoLocatoinCloudfrontUrl)
        .then((response) => {
            localStorage.setItem('loc', JSON.stringify(response.data))
            return response.data;
        })
        .then((loactionState) => {
            dispatch(dispatch_location(loactionState))
        })
        .catch((err) => {
            console.error.bind("dispatch_location reducer.js error", err);
        })
};