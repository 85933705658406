import React, { Component } from 'react';
import { connect } from 'react-redux';
import NavLink from '../NavLink';
import { getArtists, getUser, customerAddress, updateUserRelation, addProfiles, getCountries, getRegionsAPI, getAddress, generateActivationCode, addCustomerInBrainTree,} from '../../redux/reducers/reducer';
import NumberFormat from 'react-number-format';
import { isValidNumber } from 'libphonenumber-js';
import $ from 'jquery';
import _ from 'lodash';

var userinfo = {};
var customer_address = {};

var addressVisible = localStorage.getItem("adv");
let token = localStorage.getItem('token');


class CreateAnAccount extends Component {
    constructor(props) {
        super(props);
        
       
        var adr = JSON.parse(localStorage.getItem("adr"));
        var partnerhead = localStorage.getItem("partnerid");
        var partnername = "";
        if (partnerhead) {
            localStorage.setItem("partnerid", partnerhead);
        } else {
            userinfo["partnername"] = "";
        }
        var isLoggedIn = false;

        var firstname = localStorage.getItem("firstname") || ''
        var lastname = localStorage.getItem("lastname") || ''
        var email = localStorage.getItem("email") || ''
        this.state = {
            data: [],
            userinfo,
            loader: false,
            login: isLoggedIn,
            selected: '',
            navigationtab: '',
            isTop: true,
            partnercode: partnerhead,
            partnerid: partnerhead,
            partnername: partnername,
            partnerinfo: null,
            selectpartner: '',
            errors: {},
            value: '',
            firstname: firstname,
            lastname: lastname,
            email: email,
            phone: "",
            modifiedavatar: window.site.config.imagesAssetResourcesPrefix + window.site.themePrefix + 'profiles/default/avatar1.jpg' + window.site.config.imageSuffix,
            countrydefault: "",
            regiondefault: "",
            customer_address: {},
            adr: adr,
            addressVisible: addressVisible,
            countryCode:  window.countryCode || "+1 (###) ###-####",
            eventsApp: false
        };
        this.updateRelation = this.updateRelation.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.onScroll = this.onScroll.bind(this);
    }
    onScroll(isTop) {
        this.setState({ isTop });
    }
    componentDidMount() {
        let self = this;
       
        // { this.state.firstname == 'undefined' ? this.setState({ firstname: '' }) : null }
        // { this.state.lastname == 'undefined' ? this.setState({ lastname: '' }) : null }
        // { this.state.email == 'undefined' ? this.setState({ email: '' }) : null }
        // { this.state.phone == 'undefined' ? this.setState({ phone: '' }) : null }
        token = localStorage.getItem('token');
        if(!token){
            self.props.history.push("/");   
        }
        this.props.getArtists();
        this.props.getCountries();
        
        this.props.getUser(token);
        if (self.state.addressVisible === "true") {
            this.props.getAddress("custom");
        }
        if (self.state.adr && self.state.adr.extendedAddress) {
            self.props.getRegionsAPI(self.state.adr.countryCodeAlpha2)
        } else {
            self.props.getRegionsAPI(self.state.countrydefault)
        }
        userinfo["partnername"] = "";
        customer_address["countryCodeAlpha2"] = self.state.countrydefault;
        customer_address["region"] = self.state.regiondefault;
        customer_address["addresstype"] = "custom";
        this.setState({ customer_address, selectpartner: "", partnerinfo: {} });
        var partinterval = setInterval(function () {
            if (self.props.partners && self.props.partners.length > 0) {
                _.filter(self.props.partners, function (user) {
                    if (self.state.partnerid === user.partnerid) {
                        self.setState({
                            partnername: user.name,
                            partnercode: user.partnerid,
                            selectpartner: user.name + "_" + user.partnerid
                        })
                        clearInterval(partinterval);
                    }
                });
            }
        }, 1000);
        var interval = setInterval(function () {
            if (self.props.userinfo && self.props.userinfo.firstname) {
                self.setState({
                    phone: self.props.userinfo.phone,
                    firstname: self.props.userinfo.firstname,
                    lastname: self.props.userinfo.lastname,
                    email: self.props.userinfo.emailid,
                    idc: self.props.userinfo.idc,
                })
                clearInterval(interval);
            }
        }, 1000);
        setTimeout(function () {
            if (self.props.address && self.props.address[0] && self.props.address[0]._id) {
                self.props.getRegions(self.props.address[0].countryCodeAlpha2)
                delete self.props.address[0].user_id;
                delete self.props.address[0]._id
                self.setState({
                    adr: self.props.address[0]
                })
            }
        }, 5000);
      
        let userType = localStorage.getItem("userType");
        if (userType != null) {
            userType = JSON.parse(userType);
            if (userType.param === "event") {
                self.setState({ eventsApp: true })
            }
        }

        let hostName = window.location.hostname;
        hostName = hostName.split(".");
        if (Array.isArray(hostName)) {
            hostName = hostName[0].split("-");
        }
        let hostNameIsArray = Array.isArray(hostName);
        if (hostNameIsArray === true) {
            if (hostName[0] === "events")
                self.setState({ eventsApp: true })
        }
    }
    componentDidUpdate(prevProps) {
        if (prevProps.userRelationData !== this.props.userRelationData) {
           this.props.history.push("/home");
        }
        if(prevProps.countries !==  this.props.countries){
            if (localStorage.getItem("loc")) {
                let locationObj = JSON.parse(localStorage.getItem("loc"));
                let locationData = locationObj && locationObj.headers || {};
                console.log('locationData',this.props.countries,locationData['cloudfront-viewer-country-name'] && locationData['cloudfront-viewer-country-name'][0] && locationData['cloudfront-viewer-country-name'][0].value)
                let countryName = locationData['cloudfront-viewer-country-name'] && locationData['cloudfront-viewer-country-name'][0] && locationData['cloudfront-viewer-country-name'][0].value;
                let countryObj = this.props.countries.find(country => country.countryname === countryName);
                this.setState({ idc: countryObj.idc}) 
            }
        }
    }
    formValidation() {
        let errors = {};
        let formIsValid = true;
        const eml = this.validate_onchange_email();
        // if ((this.state.partnerinfo == undefined || this.state.partnerinfo == null)) {
        //     formIsValid = false;
        //     errors.name = "Please select influencer";
        // }
        if (this.state.firstname === undefined || this.state.firstname === "") {
            formIsValid = false;
            errors.firstname = "Firstname Required";
        }
        if (this.state.lastname === undefined || this.state.lastname === "") {
            formIsValid = false;
            errors.lastname = "Lastname Required";
        }
        if (this.state.email === undefined || (this.state.email === "" && !eml)) {
            formIsValid = false;
            errors.email = "Email Required";
        }
        if ((this.state.phone === undefined || this.state.phone === '' || this.state.phone === 'false') && !isValidNumber(this.state.phone)) {
            formIsValid = false;
            errors.phone = "Invalid Phone Number";
        }
        this.setState({ errors: errors });
        return formIsValid;
    }
    updateRelation(e) {
        e.stopPropagation();
        e.preventDefault();
        var self = this;
        token = localStorage.getItem('token');
        if (self.formValidation() && token) {
            $("#overlay").removeClass("hidediv");
            var userdata = {};
            userdata["firstname"] = this.state.firstname;
            userdata["lastname"] = this.state.lastname;
            userdata["emailid"] = this.state.email;
            userdata["accountType"] = window.accountType || "customer";
            if (window.paymentEnable){
                userdata["status"] = "PENDING SUBSCRIPTION";
            }
            else if(window.requiredVerification){
                userdata["status"] = "PENDING VERFICATION";
            }
            let hostName = window.location.hostname;
            hostName = hostName.split(".");
            if (Array.isArray(hostName)) {
                hostName = hostName[0].split("-");
            }
            let hostNameIsArray = Array.isArray(hostName);
            if (hostNameIsArray) {
                switch (hostName[0]) {
                    case "creators":
                        userdata["usertype"] = "creator";
                        break;
                    case "partners":
                        userdata["usertype"] = "partner";
                        break;
                    default:
                        break;
                }
            }
            userdata["phone"] = this.state.phone || 'false';
            userdata["idc"] = this.state.idc;
            // for skipping verification otp process
            if (!window.requiredVerification){
                userdata["isVerified"] = true;
             }
            // userdata["partnercode"] = this.state.partnercode;/
            // userdata["partnername"] = this.state.partnername;
            localStorage.setItem("firstname", this.state.firstname);
            localStorage.setItem("lastname", this.state.lastname);
            self.props.updateUserRelation(userdata);
            // if (requiredSelectDevice == false) {
            //     self.props.generateActivationCode('Android Mobile');
            // }
            // if (paymentEnable == false) {
            //     self.props.addCustomerInBrainTree();
            // }
            if (self.state.addressVisible === "true") {
                if (this.state.customer_address && this.state.customer_address.extendedAddress) {
                    if (self.props.address && self.props.address[0] && self.props.address[0]._id) {
                        self.props.customerAddress(this.state.customer_address, self.props.address[0]._id)
                        localStorage.setItem("adr", JSON.stringify(this.state.customer_address));
                    } else {
                        self.props.customerAddress(this.state.customer_address, "new")
                        localStorage.setItem("adr", JSON.stringify(this.state.customer_address));
                    }
                } else {
                    if (this.state.adr && this.state.adr.extendedAddress) {
                        if (self.props.address && self.props.address[0] && self.props.address[0]._id) {
                            self.props.customerAddress(this.state.adr, self.props.address[0]._id)
                            localStorage.setItem("adr", JSON.stringify(this.state.adr));
                        } else {
                            self.props.customerAddress(this.state.adr, "new")
                            localStorage.setItem("adr", JSON.stringify(this.state.adr));
                        }
                    }
                }
            }
            if (self.state.firstname) {
                self.props.addProfiles(self.state.firstname, self.state.modifiedavatar);
            }
            localStorage.setItem("profileName", self.state.firstname);
            localStorage.setItem("profileImage", self.state.modifiedavatar);
            localStorage.setItem("phone", this.state.phone);
            localStorage.setItem("transactionalmessages", this.state.phone);
        }
    }
    validate_onchange_email() {
        let formIsValid = false;
        let errors = {};
        var reg = /^([A-Za-z0-9_\-.])+@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,4})$/;
        if (reg.test(this.state.email) === false) {
            formIsValid = true;
            errors.email = "Invalid Email ID";
        }
        this.setState({ errors });
        return formIsValid;
    }
    addressChange(e) {
        var self = this;
        const name = e.target.name;
        var value = e.target.value;
        if (name === "countryCodeAlpha2") {
            self.props.getregions(value)
        }
        this.setState({ [name]: value });
        customer_address[name] = value;
        this.setState({ customer_address })
    }
    onChange(e) {
        const name = e.target.name;
        var value = e.target.value;
        let errors = Object.assign({}, this.state.errors);
        if (e.target.name === "phone") {
            let validNumber = this.validatePhoneNumber('+' + this.state.idc + ' ' + value);
            if (!validNumber) {
                delete errors[name];
                errors[name] = "Invalid Phone Number"
                this.setState({
                    [name]: value,
                    errors
                })
            } else {
                delete errors[name];
                this.setState({ [name]: value });
                userinfo[name] = value;
                this.setState({ userinfo, errors })
            }
        } else if (!!this.state.errors[name]) {
            delete errors[name];
            this.setState({
                [name]: value,
                errors
            });
        } else {
            this.setState({ [name]: value });
            userinfo[name] = value;
            this.setState({ userinfo })
            // console.log('userInfo-----------',userInfo)
        }
    }
    handleChange(e) {
        if (!!this.state.errors[e.target.name]) {
            let errors = Object.assign({}, this.state.errors);
            delete errors[e.target.name];
            this.setState({
                [e.target.name]: e.target.value,
                errors
            });
        } else {
            var partnerinfo = {};
            var selectvalue = e.target.value;
            var resultsplit = selectvalue.split("_");
            var selectname = e.target.name;
            userinfo["partnername"] = resultsplit[0];
            userinfo["partnercode"] = resultsplit[1];
            localStorage.setItem("partnerid", resultsplit[1]);
            this.setState({ [selectname]: selectvalue, partnerinfo, partnername: resultsplit[0], partnercode: resultsplit[1] });
        }
    }
    logOut(e) {
        e.stopPropagation();
        e.preventDefault();
        var myAppName = localStorage.getItem('appName');
        localStorage.clear();
        localStorage.setItem('appName',myAppName);
        window.location = '/';
    }
    onSelect2(e) {
        // console.log("cntrObj", e.target.value)
        let code = e.target.value;
        userinfo['idc'] = code;
        this.setState({ idc: code, userinfo });
        if(this.state.phone){
            this.validatePhoneNumber('+' + code + ' ' + this.state.phone);
        }
    }
    validatePhoneNumber(phoneNumber) {
        /*
        Phone number validation using google-libphonenumber
        */
        let errors = Object.assign({}, this.state.errors);
        let valid = false;
        try {
            const phoneUtil = require('google-libphonenumber').PhoneNumberUtil.getInstance();
            valid = phoneUtil.isValidNumber(phoneUtil.parse(phoneNumber));
        } catch (e) {
            console.log('error here',e);
            valid = false;
        }
        if (valid) {
            this.setState({
                message: ''
                // message: 'Phone number ' + this.getValidNumber(phoneNumber) + ' is valid',
                // color: 'green'
            });
        } else {
            errors['phone'] = 'Phone number ' + phoneNumber + ' is not valid';
            this.setState({
                errors
            });
        }
        return valid;
    }
    render() {
        let self = this;
        let payment_bg = window.site.config.imagesAssetResourcesPrefix + "screening/images/landscape_background.jpg";
        if (this.props.windowWidth < 801) {
            payment_bg = window.site.config.imagesAssetResourcesPrefix + "screening/images/landBg_portrait1.jpg";
        }
        const { value, eventsApp, idc} = this.state;
        return (
            <div>
                
                {this.props.loading && <div id="overlay" >
                    <div className="preloader">
                        <div className="loftloader-wrapper pl-frame">
                            <div className="loader">
                                <div className="spark"></div>
                                <img alt="preloader" src={window.site.preLoader} />
                                <span></span>
                            </div>
                        </div>
                    </div>
                </div>}
                <div className={this.state.isTop ? 'header_det pmt_dt' : 'header_det pmt_dt up'}>
                    <div className="wrapper">
                        <div className="logo"><img alt="logo" src={window.site.logoImage} /></div>
                        <button onClick={(e) => this.logOut(e)} className="cls"/>
                    </div>
                </div>
                <div className="payment_bg">
                    <img alt="payment" src={payment_bg} />
                </div>
                <div className="payment_blk">
                    <h2>CREATE YOUR {window.site.config.siteTitle} PROFILE</h2>
                    <div className="payment_wrp">
                        {/* <div className="pmt_lft">
                            <h3><span className="bold">STEP 2</span> OF 2</h3>
                        </div> */}
                        <div className="pmt_rgt stp1">
                            <div className="pmt_blk">
                                <div className="cc_det">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>FIRST NAME</label>
                                                <input type="text" className="capitalize" value={this.state.firstname} name="firstname" onChange={e => this.onChange(e)} />
                                                <span className="errormsg" >{this.state.errors.firstname}</span>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>LAST NAME</label>
                                                <input type="text" className="capitalize" value={this.state.lastname} name="lastname" onChange={e => this.onChange(e)} />
                                                <span className="errormsg" >{this.state.errors.lastname}</span>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <label>EMAIL</label>
                                                <input type="text" value={this.state.email} name="email" onBlur={e => this.validate_onchange_email(e)} onChange={e => this.onChange(e)} />
                                                <span className="errormsg" >{this.state.errors.email}</span>
                                            </div>
                                        </div>
                                    </div>
                                    {self.state.addressVisible === "true" ?
                                        <div className="pmt_blk">
                                            <div className="cc_det">
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <h3>SHIPPING ADDRESS</h3>
                                                        <div className="form-group">
                                                            <label>ADDRESS LINE 1</label>
                                                            <input type="text" value={this.state.streetAddress ? this.state.streetAddress : this.state.adr ? this.state.adr.streetAddress : ''} placeholder="Street address, P.O. box, Company name, C/o" name="streetAddress" onChange={e => this.addressChange(e)} />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12">
                                                        <div className="form-group">
                                                            <label>ADDRESS LINE 2</label>
                                                            <input type="text" value={this.state.extendedAddress ? this.state.extendedAddress : this.state.adr ? this.state.adr.extendedAddress : ''} placeholder="Apartment, suite, unit, building, floor etc" name="extendedAddress" onChange={e => this.addressChange(e)} />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12">
                                                        <div className="form-group">
                                                            <label>COUNTRY</label>
                                                            {/* <input type="text" className="capitalize" value={this.state.countryCodeAlpha2} name="countryCodeAlpha2" onChange={e => this.onChange(e)} /> */}
                                                            <select value={this.state.countryCodeAlpha2 ? this.state.countryCodeAlpha2 : this.state.adr ? this.state.adr.countryCodeAlpha2 : this.state.countrydefault} name="countryCodeAlpha2" className="colorselect capitalize" onChange={e => this.addressChange(e)}>
                                                                <option value="">Select Country</option>
                                                                {this.props.countries && this.props.countries.map((task, i) => {
                                                                    return (
                                                                        <option key={i} value={task.alpha2}>{task.countryname}</option>
                                                                    )
                                                                }
                                                                )}
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12">
                                                        <div className="form-group">
                                                            <label>STATE / PROVINCE / REGION</label>
                                                            <select value={this.state.region ? this.state.region : this.state.adr && this.state.adr.region ? this.state.adr.region : this.state.regiondefault} name="region" className="colorselect capitalize" onChange={e => this.addressChange(e)}>
                                                                <option value="">Select State / Province / Region</option>
                                                                {!this.props.loading && this.props.regions && this.props.regions.map((task, i) => {
                                                                    return (
                                                                        <option key={i} value={task.regionname}>{task.regionname}</option>
                                                                    )
                                                                }
                                                                )}
                                                            </select>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label>CITY</label>
                                                            <input type="text" className="capitalize" value={this.state.locality ? this.state.locality : this.state.adr ? this.state.adr.locality : ''} name="locality" onChange={e => this.addressChange(e)} />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label>ZIP / POSTAL CODE</label>
                                                            <input type="number" className="capitalize" value={this.state.postalCode ? this.state.postalCode : this.state.adr ? this.state.adr.postalCode : ''} name="postalCode" onChange={e => this.addressChange(e)} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        : null}
                                    <div className="row">
                                        {/* <div className="col-md-12">
                                            <div className="form-group">
                                                <label>PHONE</label>
                                                <PhoneInput
                                                    country="US"
                                                    placeholder="Enter phone number"
                                                    value={this.state.phone}
                                                    //onChange={e => this.onChange(e)}
                                                    onChange={phone => this.setState({ phone })}
                                                    error={value ? (isValidNumber(value) ? undefined : 'Invalid phone number') : null}
                                                    indicateInvalid />
                                                <span className="errormsg" >{this.state.errors.phone}</span>
                                            </div>
                                        </div> */}
                                        {/* <div className="col-md-12">
                                            <div className="form-group">
                                                <label>PHONE</label>
                                                <NumberFormat type="text" format={this.state.countryCode} value={this.state.phone} name="phone" placeholder="Enter Valid Phone Number" onChange={e => this.onChange(e)} />
                                                <span className="errormsg" >{this.state.errors.phone}</span>
                                            </div>
                                        </div> */}
                                        <div className="form-group col-md-4 pr-1">
                                                        <select className="field" value={this.state.idc} placeholder="Country" name="idc" onChange={e => this.onSelect2(e)}>
                                                            <option >Country</option>
                                                            {this.props.countries && this.props.countries.length > 0 && this.props.countries.map(function (country, i) {
                                                                return (
                                                                    <option key={i} value={country.idc}>{country.countryname + ' (' + country.idc + ')'}</option>
                                                                )
                                                            })}
                                                        </select>
                                                        <span className="errormsg">{this.state.errors.country}</span>
                                                    </div>
                                                    <div className="form-group col-md-8 pr-1">
                                                        <input type="text" className="field" disabled={!this.state.idc} placeholder="Enter Valid Phone Number" value={this.state.phone} name="phone" onChange={e => this.onChange(e)} />
                                                        <span className="errormsg" >{this.state.errors.phone}</span>
                                                        <div className="message" style={{ color: this.state.color }}>
                                                            {this.state.message}
                                                        </div>
                                        </div>

                                        {/* {eventsApp == true ?
                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <label>CONFIRMATION NUMBER</label>
                                                    <NumberFormat type="text" className="capitalize" value={this.state.confirmationNum} name="confirmationNum" onChange={e => this.onChange(e)} />
                                                    <span className="errormsg" >{this.state.errors.confirmationNum}</span>
                                                </div>
                                            </div> : null} */}
                                        {/* {eventsApp == true ?
                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <label>AFFILIATION</label>
                                                    <input type="text" className="capitalize" value={this.state.affiliation} name="affiliation" onChange={e => this.onChange(e)} />
                                                    <span className="errormsg" >{this.state.errors.affiliation}</span>
                                                </div>
                                            </div> : null} */}
                                        {/* {eventsApp == true ?
                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <label>COMPANY NAME</label>
                                                    <input type="text" value={this.state.companyName} name="companyName" onChange={e => this.onChange(e)} />
                                                    <span className="errormsg" >{this.state.errors.companyName}</span>
                                                </div>
                                            </div> : null} */}
                                        <div className="col-md-12 hidediv">
                                            <div className="form-group">
                                                <label>WHICH CREATOR INFLUENCED YOU?</label>
                                                <select name="selectpartner" className="colorselect" value={this.state.selectpartner} onChange={this.handleChange}>
                                                    <option value="">The {window.site.config.siteTitle} Network</option>
                                                    {this.props.partners && this.props.partners.map((task, i) => {
                                                        return (
                                                            <option key={i} value={task.name + "_" + task.partnerid}>{task.name}</option>
                                                        )
                                                    }
                                                    )}
                                                </select>
                                                <span className="errormsg" >{this.state.errors && this.state.errors.name ? this.state.errors.name : null}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <p className="free_trl">By selecting NEXT, you agree to the {window.site.config.siteTitle}<NavLink href="#" target="_blank" to='/terms' className="tos">  Terms of Service.</NavLink></p>
                            <div className="form-group">
                                <br />
                                <a href="#!" onClick={e => this.updateRelation(e)} className="submit">SUBMIT</a>
                            </div>
                        </div>
                    </div>
                </div>
                
            </div>
        );
    }
}
const mapState = ({loading, userRelationData, user, partners, userinfo, countries, regions, address }) => ({loading, userRelationData, user, partners, userinfo, countries, regions, address });
const mapDispatch = {getArtists, customerAddress, updateUserRelation, getUser, addProfiles, getCountries, getRegionsAPI, getAddress, addCustomerInBrainTree, generateActivationCode };
export default connect(mapState, mapDispatch)(CreateAnAccount);
